import { useAuth0} from "@auth0/auth0-react";
import { Button } from 'reactstrap'; 

export const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return <Button size="sm" color="primary" outline onClick={() => loginWithRedirect()}>Josette Labs</Button>;
};

export const LogoutButton = () => {
    const { logout } = useAuth0();

    const handleLogout = () => {
        logout({
            returnTo: window.location.origin
        });
    };

    return <Button size="sm" color="primary" outline onClick={handleLogout}>Log Out</Button>;
};


export const Profile = () => {
    const {user, isAuthenticated, isLoading} = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return isAuthenticated && (
        <div>
            <img src={user.picture} alt={user.name} />
            <h2>{user.name}</h2>
            <p>{user.email}</p>
        </div>
    )
};


function AuthElements() {

    return (
        <div>
            <h1>Auth</h1>
            <LoginButton />
            <Profile />
            <LogoutButton />
        </div>
    )



}

export default AuthElements;