import AdminNavMain from './AdminNavMainComponent';
import {
    Container,
    Row,
    Col,
    Alert
} from 'reactstrap';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function Admin() {

    const [numbOfConversations, setNumbOfConversations] = useState(0);
    const [numbOfUsers, setNumbOfUsers] = useState(0);
    const [numbOfMessages, setNumbOfMessages] = useState(0);
    const [averageMessagesPerConversation, setAverageMessagesPerConversation] = useState(0);
    const [percentageOfConversationsWithEmail, setPercentageOfConversationsWithEmail] = useState(0);
    const [pecentageOfEmailsWithLink, setPecentageOfEmailsWithLink] = useState(0);
    const [averageNumbMessagePerEmailConversation, setAverageNumbMessagePerEmailConversation] = useState(0);

    //To manage the authorization token
    const { getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState('emptyTokenInAdmin');

    //To manage the alert message displayed to the admin
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('info');

    useEffect (() => {

        console.log('In useEffect to get token in AdminComponent')

        const initialize = async () => {
            try {
                const jwt = await getAccessTokenSilently();
                setAccessToken(jwt);
            } catch (error) {
                console.log(error.message);
            }
        }

        initialize();

    }, [getAccessTokenSilently]);


    useEffect(() => {

        console.log('In useEffect to fetch admin stats in AdminComponent');

        const fetchAdminStats = async () => {
            try {
                const adminStatsPromise = await fetch(process.env.REACT_APP_BACKEND_URL + '/admin', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                    mode: 'cors',
                    credentials: 'include'
                });

                console.log('adminStatsPromise is: ', adminStatsPromise);

                if (!adminStatsPromise.ok) {
                    console.log('There is an error fetching the admin stats');
                    let errorMessage = "Unknown error";
                    let status = adminStatsPromise.status;

                    try {
                        const errorResponse = await adminStatsPromise.json();
                        errorMessage = errorResponse.error || errorMessage;
                    } catch (error) {
                        console.log('Error parsing error response: ', error);
                    }

                    const error = new Error(errorMessage);
                    error.status = status;
                    throw error;
                }

                const adminStatsResponse = await adminStatsPromise.json();
                console.log('adminStatsResponse is: ', adminStatsResponse);

                if(adminStatsResponse.success === true) {
                    setNumbOfConversations(adminStatsResponse.stats.numb_conversations);
                    setNumbOfUsers(adminStatsResponse.stats.numb_users);
                    setNumbOfMessages(adminStatsResponse.stats.numb_messages);
                    setAverageMessagesPerConversation(adminStatsResponse.stats.average_numb_messages_per_conversation);
                    setPercentageOfConversationsWithEmail(adminStatsResponse.stats.percentage_conversations_with_email);
                    setPecentageOfEmailsWithLink(adminStatsResponse.stats.percentage_emails_sent_with_link);
                    setAverageNumbMessagePerEmailConversation(adminStatsResponse.stats.average_numb_message_per_successful_email);
                } else {
                    console.log('Error fetching admin stats. Success if false');
                    setAlertMessage(`Error: ${adminStatsResponse.error}`);
                    setAlertColor('danger');
                }
            } catch (error) {
                if (error instanceof TypeError) {
                    setAlertMessage(`Fetch error: ${error.message}`);
                } else {
                    setAlertMessage(`Error ${error.status}: ${error.message}`);
                }
                setAlertColor('danger');
                setShowAlert(true);
            }
        }

        if( accessToken !== 'emptyTokenInAdmin') {
            fetchAdminStats();
        }

    }, [accessToken]);
        
    return (
        <>
        <AdminNavMain />
        <Container className="my-3">
            <Row>
                <Col sm={12}>
                    <div className="admin-section-title">Stats</div>
                </Col>
            </Row>
            {alertMessage && (
                <Row>
                    <Col>
                        <Alert color={alertColor} isOpen={showAlert} toggle={() => setShowAlert(false)}>{alertMessage}</Alert>
                    </Col>
                </Row>    
            )}
            <Row className="admin-stats-element pb-3">
                <div className="col-9 col-sm-auto d-flex text-align-center">
                    <div className="d-xs-inline d-sm-block">Number of users:</div>
                </div>
                <div className="col-3 col-sm-auto d-flex justify-content-center align-items-center">
                    <div className="d-xs-inline d-sm-block admin-stats-number">{numbOfUsers}</div>
                </div>
            </Row>
            <Row className="admin-stats-element mt-3 pb-3">
                <div className="col-9 col-sm-auto d-flex text-align-center">
                    <div className="d-xs-inline d-sm-block">Number of conversations:</div>
                </div>
                <div className="col-3 col-sm-auto d-flex justify-content-center align-items-center">
                    <div className="d-xs-inline d-sm-block admin-stats-number">{numbOfConversations}</div>
                </div>
            </Row>
            <Row className="admin-stats-element mt-3 pb-3">
                <div className="col-9 col-sm-auto d-flex text-align-center">
                    <div className="d-xs-inline d-sm-block">Number of messages:</div>
                </div>
                <div className="col-3 col-sm-auto d-flex justify-content-center align-items-center">
                    <div className="d-xs-inline d-sm-block admin-stats-number">{numbOfMessages}</div>
                </div>
            </Row>
            <Row className="admin-stats-element mt-3 pb-3">
                <div className="col-9 col-sm-auto d-flex text-align-center">
                    <div className="d-xs-inline d-sm-block">Average numb of messages/conversation:</div>
                </div>
                <div className="col-3 col-sm-auto d-flex justify-content-center align-items-center">
                    <div className="d-xs-inline d-sm-block admin-stats-number">{averageMessagesPerConversation}</div>
                </div>
            </Row>
            <Row className="admin-stats-element mt-3 pb-3">
                <div className="col-9 col-sm-auto d-flex text-align-center">
                    <div className="d-xs-inline d-sm-block">Average numb of messages/conversation with email:</div>
                </div>
                <div className="col-3 col-sm-auto d-flex justify-content-center align-items-center">
                    <div className="d-xs-inline d-sm-block admin-stats-number">{averageNumbMessagePerEmailConversation}</div>
                </div>
            </Row>
            <Row className="admin-stats-element mt-3 pb-3">
                <div className="col-9 col-sm-auto d-flex text-align-center">
                    <div className="d-xs-inline d-sm-block">Percentage of conversations with email:</div>
                </div>
                <div className="col-3 col-sm-auto d-flex justify-content-center align-items-center">
                    <div className="d-xs-inline d-sm-block admin-stats-number">{percentageOfConversationsWithEmail}%</div>
                </div>
            </Row>
            <Row className="admin-stats-element mt-3 pb-3">
                <div className="col-9 col-sm-auto d-flex text-align-center">
                    <div className="d-xs-inline d-sm-block">Percentage of emails that include the Calendly link:</div>
                </div>
                <div className="col-3 col-sm-auto d-flex justify-content-center align-items-center">
                    <div className="d-xs-inline d-sm-block admin-stats-number">{pecentageOfEmailsWithLink}%</div>
                </div>
            </Row>
        </Container>
        </>
    );
}

export default Admin;