import {
    Container,
    Row,
    Col
} from "reactstrap";
import NewConversationBilingual from "./NewConversationBilingualComponent";
import ExistingConversation from "./ExistingConversationComponent";
import Header from "./HeaderComponent";
import Footer from "./FooterComponent";
import React, {useState, useRef, useEffect, useCallback} from "react";
import LanguageSwitcher from "./uiComponents/LanguageSwitcherComponent";

import { translations } from "./uiComponents/translations";

import JosetteCustomers from "./uiComponents/JosetteCustomersComponent";

function Josette(){

    const [userMessage, setUserMessage] = useState(''); //the message the user is typing
    const [responseMessage, setResponseMessage] = useState(''); //the message the bot is typing
    const [messagesHistory, setMessagesHistory] = useState([]); //the history of the conversation

    const [userQuestion, setUserQuestion] = useState(''); //separating from userMessage to clean-up the input fielf after user click on `Enter`
    const inputRef = useRef(null); //reference to the input field to automatically focus on it

    const [isWriting, setIsWriting] = useState(false); //wether or not the bot is typing
    const [userEngaged, setUserEngaged] = useState(false); //wether or not the user has asked a question

    //Unique ID to identify the session
    const [sessionId, setSessionId] = useState('');

    const socketRef = useRef(null);
    const conversationId = useRef(0);
    const reconnectedAttemps = useRef(0); //number of times we tried to reconnect to the socket
    //const [isWebSocketOpen, setIsWebSocketOpen] = useState(false);
    const isWebSocketOpenRef = useRef(false);

    //Managing sessionId in localStorage
    const sId = localStorage.getItem('sId');

    //Setting the language of the bot
    const [language, setLanguage] = useState(
        (navigator.language || navigator.userLanguage).split('-')[0] === 'fr' ? 'fr' : 'en'
    );
    const t = translations[language];

    const [errorMessage] = useState(t.errorMessage); //error message to display when the connection to the server is down

    const sendErrorMessage = (messageEnteredByUser, errorMessage) => {
        const newEntry = {
            'user_message': messageEnteredByUser,
            'agent_response': errorMessage 
        };
        setMessagesHistory(prevHistory => [...prevHistory, newEntry]);
        setUserMessage(messageEnteredByUser);
        setUserQuestion(''); //This is to clear the input field
        setResponseMessage(''); //This is to clear the response message that adds up chunks of the response from the backend.
        setUserEngaged(true); //This is to show the response page
    }
    
    const sendMessage = (message) => {
        /*
        console.log('we are in sendMessage');
        console.log(`message is: ${message}`);
        console.log('typeof message is: ', typeof(message));
        console.log('sessionId is: ', sessionId);
        */
        
        const trimmedMessage = message.trim();
        if (trimmedMessage.length === 0) {
            return;
        }

        if (isWebSocketOpenRef.current) {
            console.log('we are in sendMessage');
            console.log(`message is: ${message}`);
            //const cleanMessage = message.replace(/(\r\n|\n|\r)/gm, "").trim();
            //setUserMessage(cleanMessage); //This is to show the message in the chat
            setUserMessage(message);
            setUserQuestion('');
            setUserEngaged(true);
            setIsWriting(true);
            socketRef.current.send(JSON.stringify({
                sessionId: sessionId,
                conversationId: conversationId.current,
                message: message,
                messagesHistory: messagesHistory
            }));

            if (inputRef.current) {
                // console.log('we are focusing on the inputRef');
                inputRef.current.focus();
            }

        } else {
            // const cleanMessage = message.replace(/(\r\n|\n|\r)/gm, "").trim();
            const cleanMessage = message.trim();
            sendErrorMessage(cleanMessage, errorMessage);
            // sendErrorMessage(message, errorMessage);
        }
    }

    const sendPreselectedMessage = (message) => {
        // console.log('we are in sendPreselectedMessage');

        if (isWebSocketOpenRef.current) {
            setUserMessage(message);
            setUserEngaged(true);
            setIsWriting(true);
            socketRef.current.send(JSON.stringify({
                sessionId: sessionId,
                conversationId: conversationId.current,
                message: message,
                messagesHistory: messagesHistory
            }));

            if (inputRef.current) {
                inputRef.current.focus();
            }
        } else {
            sendErrorMessage(message, errorMessage);
        }
    }

    const validateEntry = (e) => {
        if (e.key === 'Enter') {
            const trimmedMessage = e.target.value.trim();
            if (trimmedMessage.length > 0) {
                // console.log(`Message in valideEntry is: ${trimmedMessage}`);
                sendMessage(trimmedMessage);
            } else {
                // console.log('Message in valideEntry is empty');
            }
        }
    }

    const setUpSession = useCallback(async () => {
        try {
            const sessionPromise = await fetch(process.env.REACT_APP_BACKEND_URL + '/sessions', {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "consent": true,
                    "sid": sId
                })
            });
            const sessionResponse = await sessionPromise.json();
            // console.log(sessionResponse);

            const sessionId = sessionResponse.session_id;
            if (sessionId) {
                console.log('Welcome to Josette!');
                // console.log('sessionId: ', sessionId);
                localStorage.setItem('sId', sessionId);
            }

            /*
            const ipAddress = sessionResponse.ip_address;
            const returningUser = sessionResponse.returning_user;
            const newUserId = sessionResponse.user_id;
            const numbConnections = sessionResponse.numb_connections;

            console.log(`sessionId: ${sessionId}`);
            console.log(`ipAddress: ${ipAddress}`);
            console.log(`returningUser: ${returningUser}`);
            console.log(`newUserId: ${newUserId}`);
            console.log(`numbConnections: ${numbConnections}`);
            */
            
            setSessionId(sessionId);
        } catch (error) {
            // console.log('Error setting up the session: ', error);
            setSessionId('error-session-id');
        }
    }, [sId]);


    useEffect(() => {
        // console.log('In useEffect to set up the session')
        setUpSession();
    }, [setUpSession]);

    
    const setUpWebSocket = useCallback(() => {
        
        socketRef.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_BACKEND_URL + '/josette');

        socketRef.current.onopen = () => {
            // console.log('Websocket Client Connected');
            isWebSocketOpenRef.current = true;
            reconnectedAttemps.current = 0;
            // console.log('Reset reconnectedAttemps to 0');
        }

        socketRef.current.onmessage = (message) => {
            try {
                const messageData = JSON.parse(message.data);

                if (messageData.success === false) {
                    const chunkToDisplay = messageData.status;
                    setResponseMessage(prevMessage => prevMessage + chunkToDisplay);
                } else {
                    
                    // console.log('End of messages')
                    //set conversationId to the new conversationId
                    // console.log('conversationId: ', messageData.conversation_id);
                    //setConversationId(messageData.conversation_id);
                    
                    
                    conversationId.current = messageData.conversation_id;

                    /*
                    console.log('messageData: ', messageData);
                    console.log('userMessage: ', userMessage);
                    console.log('responseMessage: ', responseMessage);
                    */

                    //const cleanMessage = messageData.question.replace(/(\r\n|\n|\r)/gm, "").trim();
                    const cleanMessage = messageData.question.trim();

                    const newEntry = {
                        //'user_message': messageData.question,
                        'user_message' : cleanMessage,
                        'agent_response': messageData.response 
                    };

                    // console.log('newEntry: ', newEntry);
                    setMessagesHistory(prevHistory => [...prevHistory, newEntry]);
                    // console.log('messagesHistory: ', messagesHistory);
                    setIsWriting(false);
                    setUserMessage(''); //This is to clear the input field
                    setResponseMessage(''); //This is to clear the response message that adds up chunks of the response from the backend.
                }
            } catch (error) {
                console.log('Error parsing the message: ', error);
            }
        }

        socketRef.current.onclose = () => {
            // console.log('We are in onClose of the WebSocket Client');
            isWebSocketOpenRef.current = false;
            
            if (reconnectedAttemps.current < 3) {
                console.log('Trying to reconnect to the socket...')
                setTimeout(() => {
                    reconnectedAttemps.current += 1;
                    setUpWebSocket();
                }, 3000);
            } else {
                console.log('Reached maximum reconnection attemps. Please refresh the page.');
            }
           //setUpWebSocket();
        }

        socketRef.current.onerror = (error) => {
            // console.log('We are in onError of the WebSocket Client');
            console.log('Websocket Client Error: ', error);
        }
    }, []);

    useEffect(() => {
        
        // console.log('We are in useEffect of setting up the websocket connection to Josette');
        if (sessionId) {
            setUpWebSocket();
        } else {
            // console.log('sessionId is not set yet');
        }

        return () => {
            if (socketRef.current) {
                // console.log('We are closing the Socket at the end of UseEffect');
                socketRef.current.close();
                isWebSocketOpenRef.current = false;
            }
        }
        
    }, [setUpWebSocket, sessionId]); //This is to run the setup only once

    const MainTitle = () => {
        return (
            <>

            {!userEngaged && (
                <div style={{
                    // position: 'fixed',
                    position: 'absolute',
                    zIndex: '1000',
                    right: '0',
                    top: '56px',
                    width: '70px'}}>
                    <LanguageSwitcher language={language} setLanguage={setLanguage} />
                </div>
            )}

            <Container className="josette-main-title my-5">
                <Row>
                    <Col className="col-12 col-sm-4 d-flex align-items-center mb-sm-0 mb-4">
                        {/*}<h1>{t.welcome}</h1>{*/}
                        <Row className="d-flex align-items-center justify-content-center ">
                            <Col xs={12} className="josette-welcome-title">{t.welcome}</Col>
                        </Row>
                    </Col>
                    {/*}
                    <Col className="col-12 col-sm-8">
                        <h5>We empower innovators and forward-thinkers to redefine their industries with the unparalleled potential of generative AI technology.</h5>
                    </Col>
                    {*/}
                    <Col className="col-12 col-sm-8 d-flex align-items-center">
                        {/*}<h5>We support the evolving needs of organizational leaders, CIOs, CTOs, and CEOs, helping them refine strategies and drive action through the transformative power of generative AI.</h5>{*/}
                        <Row>
                            <Col xs={12}><span className="josette-main-tagline">{t.tagline}</span> <span className="josette-tagline-details">{t.description}</span></Col>
                            {/*}<Col xs={12} className="josette-main-tagline">Josette is a boutique consulting firm, specializing in generative AI technologies.</Col>
                            <Col xs={12} className="josette-tagline-details">{t.description}</Col>{*/}
                        </Row>
                    </Col>
                </Row>
            </Container>
            </>
        )
    }

    return (
        <>
        <Header />

        {userEngaged && (
            <>
            <MainTitle />
            <ExistingConversation
                messagesHistory={messagesHistory}
                userMessage={userMessage}
                setUserMessage={setUserMessage}
                userQuestion = {userQuestion}
                setUserQuestion = {setUserQuestion}
                responseMessage={responseMessage}
                isWriting={isWriting}
                sendMessage={sendMessage}
                language={language}
                inputRef={inputRef}
            />
            </>
        )}
        
        {!userEngaged && (
            <div className="josette-welcome-page">
            <MainTitle />
            <NewConversationBilingual
                userMessage={userMessage}
                setUserMessage={setUserMessage}
                sendMessage={sendMessage}
                validateEntry={validateEntry}
                sendPreselectedMessage={sendPreselectedMessage}
                language={language}
            />
            </div>
        )}

        {userEngaged ?
            <></>
        :   
            <>
                <JosetteCustomers language={language}/>
                <Footer language={language}/>
            </>
        }

        </>
    )
}

export default Josette;