import {useState, useEffect} from 'react';

const TypewriterEffect = ({text, speed = 100}) => {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
        setDisplayedText('');
    }, [text]);

    useEffect(() => {
        if (displayedText.length < text.length) {
            const timer = setTimeout(() => {
                setDisplayedText(text.substring(0, displayedText.length + 1));
            }, speed);

            return () => clearTimeout(timer);
        }
    }, [displayedText, text, speed]);

    return <div>{displayedText}</div>;
}

export default TypewriterEffect;