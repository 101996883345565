import React, {useEffect, useState, useRef, useCallback} from 'react';
import {Button, Container, Row, Col, Alert, Input, Modal, ModalHeader, ModalBody, Spinner, Label, FormGroup} from 'reactstrap';
import AdminNavMain from "./AdminNavMainComponent";
import { useAuth0 } from '@auth0/auth0-react';
import DisplayConversationsTable from './DisplayConversationsTableComponent';
//import { formatTimestamp } from './AdminFuncsComponents';
import AdminPagination from './AdminPaginationComponent';

function AdminConversations() {

    const [conversationsList, setConversationsList] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    

    //For managing the alert/info message at the top of the table
    const [alertMessage, setAlertMessage] = useState(null); //The message to display in the alert
    const [showAlert, setShowAlert] = useState(false); //Whether or not to show the alert message
    const [alertColor, setAlertColor] = useState('info'); //The color of the alert message
    const toggleAlert = () => {
        setShowAlert(!showAlert);
        //setAlertMessage(null);
    };

    //For managing the authentication process
    const { getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState('emptyTokenInAdmin');

    const [conversationsToProcess, setConversationsToProcess] = useState([]);

    //When processingConversations is true, we are displaying the modal with the messages being processed
    const [processingConversations, setProcessingConversations] = useState(false);
    
    const [collectedMessages, setCollectedMessages] = useState([]);
    const [conversationsProcessed, setConversationsProcessed] = useState([]);
    const [currentMessage, setCurrentMessage] = useState('');

    const [processNextSteps, setProcessNextSteps] = useState(false);
    const [processUserData, setProcessUserData] = useState(false);

    const toggleProcessNextSteps = () => setProcessNextSteps(!processNextSteps);
    const toggleProcessUserData = () => setProcessUserData(!processUserData);

    //We want to differentiate between a click on the checkbox and a click on the row
    //With onChange the propagation is sill happening because the onClick event on the row is being triggered before the onChange event.
    const checkboxClickRef = useRef(false);
    const handleCheckboxMouseDown = () => {
        checkboxClickRef.current = true;
    };

    //Change size of the table for smaller screen
    const [isTableReduced, setIsTableReduced] = useState(false);

    //State of the websocket connection
    const [isWebSocketOpen, setIsWebSocketOpen] = useState(false);

    const selectAllConversations = (event) => {
        console.log('In selectAllConversations');
        console.log('Value of event.target.checked: ', event.target.checked);

        console.log('Value of processNextSteps: ', processNextSteps);
        console.log('Value of processUserData: ', processUserData);

        if (event.target.checked) {
            const allConversationsId = conversationsList.map((conversation) => conversation.id);
            setConversationsToProcess(allConversationsId);
        } else {
            setConversationsToProcess([]);
        }
    };

    //Managing pagination
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);


    useEffect( ()=> {

        const initialize = async () => {
            try {
                const jwt = await getAccessTokenSilently();
                setAccessToken(jwt);
            } catch (error) {
                console.log(error.message);
            }
        }

        initialize();

    }, [getAccessTokenSilently])


    const getConversations = useCallback(async () => {
        console.log('we are in getConversations');
        console.log(`Value of page is: ${page}`);
        try {
            const conversationsPromise = await fetch(process.env.REACT_APP_BACKEND_URL + `/conversations?page=${page}&limit=30`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                mode: 'cors',
                credentials: 'include'
            });

            if (!conversationsPromise.ok) {
                console.log('There is an error fetching conversations');
                let errorMessage = "Unknown error";
                let status = conversationsPromise.status;

                try {
                    const errorResponse = await conversationsPromise.json();
                    errorMessage = errorResponse.error || errorMessage;
                } catch (err) {
                    console.log('There is an error parsing the error message');
                }
                
                const error = new Error(errorMessage);
                error.status = status;
                throw error;
            }

            const conversations = await conversationsPromise.json();
            console.log(conversations.conversations);
            setConversationsList(conversations.conversations);
            setTotalPages(conversations.total_pages);
        } catch (err) {
            if (err instanceof TypeError) {
                setAlertMessage(`Fetch error: ${err.message}`);
            } else {
                setAlertMessage(`Error ${err.status}: ${err.message}`);
            }
            setAlertColor('danger');
            setShowAlert(true);
        }
    }, [page, accessToken]);


    const socketRef = useRef(null);
    const reconnectedAttempts = useRef(0);

    const setUpWebSocket = useCallback(() => {
        console.log('In setUpWebSocket');
        if (socketRef.current) {
            socketRef.current.close();
        }

        socketRef.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_BACKEND_URL + `/process-data?token=${accessToken}`);

        socketRef.current.onopen = () => {
            console.log('WebSocket is open');
            setIsWebSocketOpen(true);
        }

        socketRef.current.onmessage = (message) => {
            try {
                const messageData = JSON.parse(message.data);

                if (messageData.error) {
                    console.log('There is an error processing the message');
                    console.log(messageData.description);
                    /*
                    setCollectedMessages([]); //Messages recieved from the websocket
                    setConversationsProcessed([]); //Conversations that have been processed by our backend (list of conversation ids)
                    setProcessingConversations(false);
                    */

                    setAlertMessage(`WebSocket error: ${messageData.description}`);
                    setShowAlert(true);
                    setAlertColor('danger');
                    
                    resetProcessingState();

                } else if (messageData.completed) {
                    console.log('Conversation list has been processed');
                    /*
                    setCollectedMessages([]);
                    setConversationsProcessed([]);
                    setProcessingConversations(false);
                    */
                    console.log('Value of page beofre setConversationsToProcess - onMessage: ', page);
                    
                    // fetchUpdatedConversations(); //Fetch the updated list of conversations

                    console.log('Value of page in setUpWebSocket - onMessage: ', page);
 
                    getConversations(); //Fetch the updated list of conversations

                    setConversationsToProcess([]); //Clear the list of conversations to process
                    resetProcessingState();
                    
                } else if (messageData.success) {
                    console.log(`Conversation ${messageData.conversation_id} has been processed`);
                    setCollectedMessages((collectedMessages) => [messageData.message, ...collectedMessages]);
                    setConversationsProcessed((conversationsProcessed) => [messageData.conversation_id, ...conversationsProcessed]);
                } else {
                    //When we receive a message indicating that we are starting to process a new conversation
                    setCurrentMessage(messageData.message);
                    console.log(`Starting to process conversation: ${messageData.conversation_id}`);
                }
            } catch (err) {
                console.log('Error parsing the message', err);
            }
        }

        socketRef.current.onclose = () => {
            console.log('WebSocket is closed');
            setIsWebSocketOpen(false);
            if (reconnectedAttempts.current < 3) {
                setTimeout(() => {
                    reconnectedAttempts.current += 1;
                    setUpWebSocket();
                }, 3000);
            } else {
                console.log('Max reconnect attempts reached. Please refresh the page.');
                /*
                setCollectedMessages([]);
                setConversationsProcessed([]);
                setProcessingConversations(false);
                */
                setAlertMessage('WebSocket error: Max reconnect attempts reached. Please refresh the page.');
                setAlertColor('danger');
                setShowAlert(true);
                resetProcessingState();
            }
        }

        socketRef.current.onerror = (error) => {
            console.log('WebSocket has an error: ', error);
            /*
            setCollectedMessages([]);
            setConversationsProcessed([]);
            setProcessingConversations(false);
            */
           resetProcessingState();
        }
    }, [accessToken, page, getConversations]);


    const resetProcessingState = () => {
        setCollectedMessages([]); //Messages recieved from the websocket
        setConversationsProcessed([]); //Conversations that have been processed by our backend (list of conversation ids)
        setProcessingConversations(false);
    }




    /*
    useEffect (() => {

        console.log('In useEffect to get Conversation and set up WebSocket in AdminConversations');

        if (accessToken !== 'emptyTokenInAdmin') {

            console.log('AccessToken is not emptyTokenInAdmin in useEffect in AdminConversations');

            getConversations();
            setUpWebSocket();
        }

        return () => {
            console.log('In cleanup function of useEffect in AdminConversations');
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    // }, [accessToken, setUpWebSocket, page]);
    }, [accessToken, setUpWebSocket, page, getConversations]);
    */

    useEffect (() => {
        if (accessToken !== 'emptyTokenInAdmin') {
            getConversations();
        }
    }, [accessToken, page, getConversations]);


    useEffect (() => {
        if (accessToken !== 'emptyTokenInAdmin') {
            setUpWebSocket();
        }

        return () => {
            console.log('In cleanup function of useEffect in AdminConversations');
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [accessToken]); //To prevent multiple connections to the websocket


    const handleRowClick = (conversation) => {

        if (checkboxClickRef.current) {
            checkboxClickRef.current = false;
            return;
        }

        if (selectedConversation === conversation.id) {
            setSelectedConversation(null);
            console.log(`Conversation ${conversation.id} is collapsing`);
        } else {
            setSelectedConversation(conversation.id);
            console.log(`Conversation ${conversation.id} is expanding`);
        }
    };


    const handleCheckboxChange = (event, conversation) => {
        event.stopPropagation();
        console.log('In handleCheckboxChange');
        console.log('Conversation id: ', conversation.id);
        if (event.target.checked) {
            setConversationsToProcess([...conversationsToProcess, conversation.id]);
        } else {
            const newConversationsToProcess = conversationsToProcess.filter((id) => id !== conversation.id);
            setConversationsToProcess(newConversationsToProcess);
        }
    };


    const sendDataWebsocket = () => {

        console.log('In sendDataWebsocket');
        console.log('List of conversations to process: ', conversationsToProcess);
        console.log('Value of page is: ', page);

        if (conversationsToProcess.length === 0) {
            setShowAlert(true);
            setAlertColor('danger');
            setAlertMessage('Select conversations before processing.');
            return;
        }

        if (!processNextSteps && !processUserData) {
            setShowAlert(true);
            setAlertColor('danger');
            setAlertMessage('Select summary or user data before processing.');
            return;
        }

        if (isWebSocketOpen) {
            setProcessingConversations(true);
            socketRef.current.send(JSON.stringify({
                conversationsToProcessList: conversationsToProcess,
                processNextSteps: processNextSteps,
                processUserData: processUserData
            }));
        } else {
            setCollectedMessages([]);
            setConversationsProcessed([]);
            setProcessingConversations(false);
            setAlertMessage('WebSocket error: Connection is closed. Please refresh the page.');
            setAlertColor('danger');
            setShowAlert(true);
        }
    };

    const successfullyDeletedConversations = useRef([]);
    const errorDeletingConversations = useRef([]);

    const deleteConversation = async (event, conversationId, multipleConversations=false) => {
        console.log('In deleteConversation');
        event.stopPropagation();
        console.log(`Want to DELETE conversation #${conversationId}`);

        if (conversationsToProcess.includes(conversationId)) {
            try {
                const deleteConversationPromise = await fetch(process.env.REACT_APP_BACKEND_URL + '/conversations/' + conversationId, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                    mode: 'cors',
                    credentials: 'include',
                });

                if (!deleteConversationPromise.ok) {
                    console.log('There is an error deleting conversation');
                    let errorMessage = "Unknown error";
                    let status = deleteConversationPromise.status;

                    try {
                        const errorResponse = await deleteConversationPromise.json();
                        errorMessage = errorResponse.error || errorMessage;
                    } catch (err) {
                        console.log('There is an error parsing the error message');
                    }

                    const error = new Error(errorMessage);
                    error.status = status;
                    throw error;
                }


                //const requestStatus = deleteConversationPromise.status;
                const deleteConversationResponse = await deleteConversationPromise.json();
                
                console.log(deleteConversationResponse);
                
                if (deleteConversationResponse.success) {
                    console.log('Conversation deleted');
                    //alert(deleteConversationResponse.message);
                    
                    /*
                    const newConversationsList = conversationsList.filter((conversation) => conversation.id !== deleteConversationResponse.conversation_id);
                    setConversationsList(prevState => newConversationsList);
                    */
                    setConversationsList(conversationsList => conversationsList.filter((conversation) => conversation.id !== deleteConversationResponse.conversation_id));

                    /*
                    const newConversationsToProcess = conversationsToProcess.filter((id) => id !== deleteConversationResponse.conversation_id);
                    setConversationsToProcess(prevState => newConversationsToProcess);
                    */
                   setConversationsToProcess(conversationsToProcess => conversationsToProcess.filter((id) => id !== deleteConversationResponse.conversation_id));

                    if (!multipleConversations) {
                        setAlertMessage(deleteConversationResponse.message);
                        setAlertColor('success');
                        setShowAlert(true);
                    } else {
                        successfullyDeletedConversations.current.push(conversationId);
                    }

                } else {

                    if (!multipleConversations) {
                        setAlertMessage(`Error: ${deleteConversationResponse.error}`);
                        setAlertColor('danger');
                        setShowAlert(true);
                    } else {
                        errorDeletingConversations.current.push(conversationId);
                    }

                }
                    
            } catch (err) {
                console.log(`There is an error deleting conversation: ${err}`);
                if (!multipleConversations) {
                    if (err instanceof TypeError) {
                        setAlertMessage(`Fetch error: ${err.message}`);
                    } else {
                        setAlertMessage(`Error ${err.status}: ${err.message}`);
                    }
                    setAlertColor('danger');
                    setShowAlert(true);
                } else {
                    errorDeletingConversations.current.push(conversationId);
                }
            }

        } else {

            setShowAlert(true);
            setAlertColor('danger');
            setAlertMessage(`Select conversation ${conversationId} before deleting`);
        } 
    };


    const deleteAllSelectedConversations = async (event) => {
        console.log('In deleteAllSelectedConversations');

        successfullyDeletedConversations.current = [];
        errorDeletingConversations.current = [];

        console.log('List of conversations to process: ', conversationsToProcess);

        if (conversationsToProcess.length === 0) {
            setShowAlert(true);
            setAlertColor('danger');
            setAlertMessage('Select conversations before deleting.');
            return;
        }

        if (conversationsToProcess.length === 1) {
            await deleteConversation(event, conversationsToProcess[0]);
            return;
        }

        try {
            await Promise.all(conversationsToProcess.map(async (conversationId) => {
                console.log('Trying to delete conversation: ', conversationId);
                await deleteConversation(event, conversationId, true);
                console.log('Conversation id: ', conversationId);
            }));
            console.log('Finished processing all conversation deletion requests');
        } catch (err) {
            console.log('There is an error deleting all conversations');
        }

        const listConversationsDeleted = successfullyDeletedConversations.current.join(', ');
        const listConversationsError = errorDeletingConversations.current.join(', ');

        if (errorDeletingConversations.current.length > 0 && successfullyDeletedConversations.current.length === 0) {
            setAlertMessage(`No conversations were deleted - Error deleting conversations: ${listConversationsError}`);
            setAlertColor('danger');
        } else if (errorDeletingConversations.current.length > 0 && successfullyDeletedConversations.current.length > 0) {
            setAlertMessage(`Some errors - Conversations ${listConversationsDeleted} deleted successfully. Errors occurred with ${listConversationsError}.`);
            setAlertColor('warning');
        } else {
            setAlertMessage(`All conversations deleted - Conversations ${listConversationsDeleted} deleted successfully.`);
            setAlertColor('success');
        }

        setShowAlert(true);
        setConversationsProcessed([]);
    };


    const displayMessagesModal = collectedMessages.map((message, index) => {
        return (
            <Row key={index}>
                <Col xs={12} className="d-flex align-items-center justify-content-center my-2">
                    <div>{message} <i className="fa fa-check display-step-completed" aria-hidden="true"></i></div>
                </Col>
            </Row>
        )
    });


    const displayConversations = ({conversationsList}) => {
        return (
            <Container>

                {alertMessage && (
                    <Row className="mt-3">
                        <Alert color={alertColor} isOpen={showAlert} toggle={toggleAlert}>
                            {alertMessage}
                        </Alert> 
                    </Row>
                )}

            <Modal isOpen={processingConversations}>
                <ModalHeader>
                    <div style={{textAlign: 'center'}}>Processing conversation {conversationsProcessed.length + 1}/{conversationsToProcess.length}</div>
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col className="d-flex justify-content-center my-2">
                                <div>{currentMessage}...</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center my-2">
                                <Spinner color="primary" />
                            </Col>
                        </Row>
                        {displayMessagesModal}
                    </Container>
                </ModalBody>
            </Modal>

            <DisplayConversationsTable
                selectedConversation = {selectedConversation}
                conversationsList = {conversationsList}
                handleRowClick = {handleRowClick}
                isTableReduced = {isTableReduced}
                conversationsToProcess = {conversationsToProcess}
                handleCheckboxMouseDown = {handleCheckboxMouseDown}
                handleCheckboxChange = {handleCheckboxChange}
                deleteConversation = {deleteConversation}
                //formatTimestamp = {formatTimestamp} 
            />

            {/*}
            <Table {...(!selectedConversation ? {hover:true} : {})}>
                <thead>
                    <tr>
                        <th>Timestamp</th>
                        {!isReduced && <th>&#128100;</th>}
                        <th>&#128233;</th>
                        {!isReduced && <th>Next steps</th>}
                        <th>&#9989;</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {conversationsList.map((conversation) => (
                        <React.Fragment key={conversation.id}>
                            <tr 
                                onClick={() => handleRowClick(conversation)}
                                className={selectedConversation === conversation.id ? 'table-light' : ''}>
                                <td className="center-content">{formatTimestamp(conversation.timestamp)}</td>
                                {!isReduced && <td className="center-content">{conversation.user_id}</td>}
                                <td className="center-content">{conversation.messages.length}</td>
                                {!isReduced && <td className="center-content">{conversation.next_steps}</td>}
                                <td className="center-content">
                                    <Input
                                        type="checkbox"
                                        checked={conversationsToProcess.includes(conversation.id)}
                                        onMouseDown={handleCheckboxMouseDown}
                                        onChange={(event) => handleCheckboxChange(event, conversation)}
                                    />
                                </td>
                                <td className="center-content"><Button size="sm" color="danger" onClick={(event) => deleteConversation(event, conversation)}>Delete</Button></td>
                            </tr>
                            {selectedConversation && selectedConversation === conversation.id && (
                                <tr>
                                    <td colSpan="6">
                                        {conversation.messages.map((message) => (
                                            <div className="row" key={message.id}>
                                                {message.is_user_message ?
                                                    <div className="admin-user-message col-7">
                                                        <p>User message:</p>
                                                        <p>{message.text}</p>
                                                    </div>
                                                    :
                                                    <div className="admin-josette-message col-7 offset-5">
                                                        <p>Josette message:</p>
                                                        <p>{message.text}</p>
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>                

            </Table>
            {*/}

            </Container>
        )
    }

    return (
        <>  
            <AdminNavMain />

            <Container className='my-3'>
                <Row>
                    <Col xs={12}>
                        <div className="admin-section-title">Conversations</div>
                    </Col>
                </Row>
                <Row>
                    <div className="col-12 d-flex justify-content-end mb-3">
                        <Button color="primary" onClick={sendDataWebsocket}>Process data</Button>
                    </div>
                    <div className="col-12 col-md-4 offset-md-8 col-lg-3 offset-lg-9 col-xl-2 offset-xl-10 d-flex justify-content-start mb-3">
                        <div className="form-check form-switch d-flex align-items-center justify-content-end">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                id="switchSelectAllConversations"
                                onChange={selectAllConversations}
                            />
                            <label className="form-check-label" htmlFor="switchSelectAllConversations">All conversations</label>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 offset-md-8 col-lg-3 offset-lg-9 col-xl-2 offset-xl-10 d-flex justify-content-start mb-3">
                        <FormGroup check className='d-flex justify-content-end'>
                            <Input type='checkbox' id="nextSteps" onClick={toggleProcessNextSteps} />
                            <Label check for="nextSteps" className="ms-2">Summary</Label>
                        </FormGroup>
                    </div>
                    <div className="col-12 col-md-4 offset-md-8 col-lg-3 offset-lg-9 col-xl-2 offset-xl-10 d-flex justify-content-start">
                        <FormGroup check className='mb-3 d-flex justify-content-end'>
                            <Input type='checkbox' id="userData" onClick={toggleProcessUserData}/>
                            <Label check for="userData" className="ms-2">User data</Label>
                        </FormGroup>
                    </div>
                </Row>
            {/*}
            </Container>

            <Container className="my-3">
                <Row>
                    <Col xs={12}>
                        <div className="admin-section-title">Conversations</div>
                    </Col>
                </Row>
                <Row style={{border: '1px solid orange'}}>
                    <Col xs={12} className="d-flex justify-content-end mt-3">
                        <div style={{border: '1px solid black'}}>
                            <FormGroup check className='mb-3 d-flex'>
                                <Input type='checkbox' id="selectAll" onClick={selectAllConversations}/>
                                <Label check for="selectAll" className="ms-2">Select all conversations</Label>
                            </FormGroup>
                            <FormGroup check className='mb-3 d-flex'>
                                <Input type='checkbox' id="nextSteps" onClick={toggleProcessNextSteps}/>
                                <Label check for="nextSteps" className="ms-2">Next steps</Label>
                            </FormGroup>
                            <FormGroup check className='mb-3 d-flex'>
                                <Input type='checkbox' id="userData" onClick={toggleProcessUserData}/>
                                <Label check for="userData" className="ms-2">User data</Label>
                            </FormGroup>
                            <FormGroup check className='mb-3 d-flex'>
                                <Button size="sm" color="primary" onClick={sendDataWebsocket}>Process data</Button>
                            </FormGroup>
                        </div>
                    </Col>
                </Row>
            {*/}
                <Row>
                    {/*}
                    <Col className="d-flex justify-content-start">
                        <Button size="sm" color="secondary" onClick={() => setIsTableReduced(!isTableReduced)}>{isTableReduced ? <span>Expand</span> : <span>Reduce</span>} Table</Button>
                    </Col>
                    {*/}
                    <Col className="d-flex justify-content-between">
                        <div className="form-check form-switch d-flex align-items-center">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                id="switchReduceTable"
                                onChange={() => setIsTableReduced(!isTableReduced)}
                            />
                            <label className="form-check-label" htmlFor="switchReduceTable">Reduce table size</label>
                        </div>
                        {isTableReduced && (
                            <Button color="danger" onClick={deleteAllSelectedConversations}>
                                Delete
                            </Button>
                        )}
                    </Col>
                </Row>
            </Container>
            {displayConversations({ conversationsList })}
            <Container className="my-3">
                <Row>
                    <Col className="d-flex justify-content-center">
                        <AdminPagination
                            page={page}
                            setPage={setPage}
                            totalPages={totalPages}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AdminConversations;