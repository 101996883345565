import { Container, Row, Col } from 'reactstrap';
import { useEffect, useRef, useState, useCallback } from 'react';
import Markdown from 'react-markdown';
import FollowUpQuestions from './uiComponents/FollowUpQuestionsComponent';


function ExistingConversation({messagesHistory, responseMessage, userMessage, isWriting, sendMessage, language, userQuestion, setUserQuestion, inputRef}) {
    
    const bottomRef = useRef(null);
    const [textareaHeight, setTextareaHeight] = useState('auto');

    // const [userEngagedInTyping, setUserEngagedInTyping] = useState(false);

    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [responseMessage, userMessage]);

    const adjustTextareaHeight = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.style.height = 'auto';
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
            setTextareaHeight(`${inputRef.current.scrollHeight}px`);
        }
    },[inputRef]);

    
    useEffect(() => {
        adjustTextareaHeight();
    }, [userQuestion, adjustTextareaHeight]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            setUserQuestion(userQuestion + '\n');
        } else if (e.key === 'Enter') {
            e.preventDefault();
            sendMessage(userQuestion);
            setTextareaHeight('auto');
        }
    };


    const handleInput = (e) => {
        setUserQuestion(e.target.value);
        setTextareaHeight('auto');
        adjustTextareaHeight();
        // setUserEngagedInTyping(true); // User is typing or has started typing
    }

    return (
        <>

        {/*}<Container className="no-padding-left" style={{overflowY: 'auto', marginBottom: '100px'}}>{*/}
        {/*}<Container style={{overflowY: 'auto', marginBottom: '100px', height: 'calc(100vh - 150px)', paddingBottom: '100px'}}>{*/}
        <Container style={{overflowY: 'auto', marginBottom: '120px'}}>

            {/*}
            <Row>
                <Col>
                    <div style={{ whiteSpace: 'pre-wrap', textAlign: 'left'}}>
                        {messagesHistory.map((message, index) => (
                                <div key={index} className='mb-5'>
                                    <div className="mb-3"><span className="user-message-conversation">{message.user_message}</span></div>
                                    <div className="mb-3 add-padding-left"><Markdown>{message.agent_response}</Markdown></div>
                                </div>
                            )
                        )}
                    </div>
                </Col>
            </Row>
            {*/}

            <Row>
                <Col>
                    <div style={{ whiteSpace: 'pre-wrap', textAlign: 'left'}}>
                        {messagesHistory.map((message, index) => (
                                <div key={index} className='mb-5'>
                                    <div className="mb-3"><span className="user-message-conversation">{message.user_message}</span></div>
                                    <div className="mb-3 add-padding-to-answer"><Markdown>{message.agent_response}</Markdown></div>
                                </div>
                            )
                        )}
                    </div>
                </Col>
            </Row>



            <Row>
                <Col>
                    {isWriting ?
                        <div className="new-question">
                            <div className="d-flex" style={{whiteSpace: 'pre-wrap'}}>
                                <div className="mb-3 user-message-conversation">
                                    {/*}<b><TypewriterEffect text={userMessage} speed={25}/></b>{*/}
                                    {userMessage.trim()}
                                </div>
                            </div>
                            <div style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }} className="mb-3 add-padding-to-answer">
                                <Markdown>{responseMessage}</Markdown>
                            </div>
                        </div>
                    : <></>
                    }
                </Col>
            </Row>

            <div ref={bottomRef}/>
        
        </Container>

        <Container style={{ position: 'fixed', left: 0, right: 0, bottom: '30px', padding: '10px', backgroundColor: '#fff' }}>
            
            {(!isWriting) ? 
                <FollowUpQuestions
                    messageHistory={messagesHistory}
                    userMessage={userMessage}
                    responseMessage={responseMessage}
                    sendMessage={sendMessage}
                    language={language}
                />
            : <></>
            }

            <Row>
                <Col className="sm-12 d-flex justify-content-center align-items-center">
                    <div className="d-flex search-box-existing">
                        <textarea
                            placeholder="Message Josette..."
                            value={userQuestion}
                            onChange={handleInput}
                            onKeyDown={handleKeyDown}
                            ref={inputRef}
                            style={{
                                height: textareaHeight,
                                overflowY: 'hidden',
                                resize: 'none',
                                width: '100%',
                                //height: '100%',
                                border: 'none',
                                outline: 'none',
                                lineHeight: '1.5em',
                                fontSize: '16px',
                            }}
                            rows={1}
                        />
                        <button className="search-button-existing d-flex justify-content-center align-items-center" onClick={() => sendMessage(userQuestion)} disabled={isWriting}>
                            <i className="fa-solid fa-arrow-right" style={{color: "#000000;"}}></i>
                        </button>
                    </div>
                </Col>
            </Row>

        </Container>
        
        {/*}
        <div style={{
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: '0',
            height: '40px', // Adjust the height as needed
            backdropFilter: 'blur(10px)',
            background: 'rgba(255, 255, 255, 0.6)',
            zIndex: 0
        }} />
        {*/}

        {/*}
        <div style={{
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: '0',
            height: '40px', // Adjust the height as needed
            background: 'rgba(255, 255, 255, 0.9)',
            zIndex: 0,
            filter: 'blur(10px)',
            transform: 'translateY(50%)'
        }} />
        {*/}

        <div className="blurred-section" />

        </>
    )
}

export default ExistingConversation;