import { suggestedQuestions as allSuggestedQuestions } from "./suggestedQuestions";
import { Row, Col } from "reactstrap";
import { useState, useMemo, useEffect } from "react";


function FollowUpQuestions({messageHistory, sendMessage, language}) {
    
    const initialListOfSuggestedQuestions = useMemo(() => allSuggestedQuestions[language], [language]); //list of suggested questions in the selected language
    const [numbOfRandomQuestions, setNumbOfRandomQuestions] = useState(4);
    
    const [listOfAvailableSuggestedQuestions, setListOfAvailableSuggestedQuestions] = useState(initialListOfSuggestedQuestions);
    const [listOfFollowUpQuestionsAsked, setListOfFollowUpQuestionsAsked] = useState([]);

    const [listOfFollowUpQuestions, setListOfFollowUpQuestions] = useState([]);
    const [randomQuestions, setRandomQuestions] = useState([]);

    const [refreshCount, setRefreshCount] = useState(0);

    const [numbDisplayedQuestions, setNumbDisplayQuestions] = useState(() => {
        if (window.innerWidth <= 768) {
            return 1;
        } else {
            return 2;
        }
    });

    const [followUpQuestionsReceived, setFollowUpQuestionsReceived] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);

            if (window.innerWidth <= 768) {
                console.log('Setting number of displayed questions to 1');
                setNumbDisplayQuestions(1);
            } else {
                console.log('Setting number of displayed questions to 2');
                setNumbDisplayQuestions(2);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    

    useEffect(() => {

        console.log('Fetching follow-up questions from backend');
        console.log('listOfAvailableSuggestedQuestions:', listOfAvailableSuggestedQuestions);

        const getQuestionsFromBackend = async () => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/follow-up-questions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    messageHistory,
                    numbOfRandomQuestions,
                    language,
                    listOfAvailableSuggestedQuestions,
                    listOfFollowUpQuestionsAsked
                }),
            });
            const data = await response.json();
            console.log(data);
            const listOfQuestions = data.follow_up_questions;
            setListOfFollowUpQuestions(listOfQuestions);
            console.log('number of displayed questions:', numbDisplayedQuestions);
            setRandomQuestions(listOfQuestions.slice(0, numbDisplayedQuestions));
            console.log('List of follow-up questions:', randomQuestions);
            setFollowUpQuestionsReceived(true);
        }

        getQuestionsFromBackend();

    }, [language, messageHistory, listOfAvailableSuggestedQuestions, listOfFollowUpQuestionsAsked, numbOfRandomQuestions, numbDisplayedQuestions]);

    const handleSuggestedMessage = (suggestedQuestion) => {
        sendMessage(suggestedQuestion);
        setListOfFollowUpQuestionsAsked([...listOfFollowUpQuestionsAsked, suggestedQuestion]);
        setFollowUpQuestionsReceived(false);
        //setAskedQuestions([...askedQuestions, suggestedQuestion]);

        // Update list of suggestions
        //setListOfAvailableSuggestedQuestions(() => listOfAvailableSuggestedQuestions.filter(question => !askedQuestions.includes(question)));
        //setRandomQuestions(getRandomQuestions(listOfAvailableSuggestedQuestions, 2));
    }

    const refreshSuggestions = () => {
        //const availableQuestions = listOfAvailableSuggestedQuestions.filter(question => !askedQuestions.includes(question));
        console.log('Refreshing suggestions: ', refreshCount);
        //setRandomQuestions(getRandomQuestions(availableQuestions, 2));

        const newRefreshCount = refreshCount + numbDisplayedQuestions;
        if (newRefreshCount < listOfFollowUpQuestions.length) {
            setRandomQuestions(listOfFollowUpQuestions.slice(newRefreshCount, newRefreshCount + numbDisplayedQuestions));
            setRefreshCount(newRefreshCount);
        } else {
            setRefreshCount(0);
            setRandomQuestions(listOfFollowUpQuestions.slice(0, numbDisplayedQuestions));
        }

    }

    return (

        <>
        {followUpQuestionsReceived ?
            <Row className="d-flex justify-content-center align-items-center mt-1 mb-3">
                {randomQuestions.slice(0,numbDisplayedQuestions).map((question, index) => (
                    <Col xs="auto" sm="auto" key={index} className="d-flex justify-content-center align-items-center">
                        <button
                            className="follow-up-question"
                            value={question}
                            onClick={() => handleSuggestedMessage(question)}
                        >{question}</button>
                    </Col>
                ))}
                <Col className="d-flex justify-content-center align-items-center" xs="auto" sm="auto">
                    <button
                        className="refresh-follow-up-questions d-flex justify-content-center align-items-center"
                        value="Refresh"
                        onClick={refreshSuggestions}
                    >
                        <i className="fa-solid fa-rotate-left" style={{color: "#000000;"}}></i>
                    </button>
                </Col>
            </Row>
        : <></>
        }
        </>
    )
}

export default FollowUpQuestions;