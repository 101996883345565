import { Container, Row, Col } from 'reactstrap';
import {SuggestionButton} from './uiComponents/SuggestionButtonComponent';
import TypewriterEffect from './uiComponents/TypewriterEffectComponent';
import { translations } from './uiComponents/translations';

function NewConversationBilingual({userMessage, setUserMessage, sendMessage, validateEntry, sendPreselectedMessage, language}){
    
    const t = translations[language];

    return (
        <Container className="josette-main-section">

            <Row style={{width: '100%'}}>
                <Col className="d-flex justify-content-center">
                    <div className="search-header">
                        <div className="josette-tagline-details" style={{textAlign: 'left'}}>
                            <TypewriterEffect text={t.helpPrompt} speed={25}/>
                        </div>
                        <div className="search-box">
                            <textarea
                                type="text"
                                placeholder={t.askPlaceholder}
                                value={userMessage}
                                onChange={(e) => setUserMessage(e.target.value)}
                                onKeyDown={validateEntry}
                                rows={4}
                            />
                            <button className="search-button d-flex justify-content-center align-items-center" onClick={() => sendMessage(userMessage)}><i className="fa-solid fa-arrow-right"></i></button>
                        </div>
                        <div className="row suggestions">
                            <div className="col-md-6">
                                <SuggestionButton icon="&#x1F4A1;" text={t.whatIsJosette} onClick={() => sendPreselectedMessage(t.whatIsJosette)}/>
                            </div>
                            <div className="col-md-6">
                                <SuggestionButton icon="&#x1F9E0;" text={t.services} onClick={() => sendPreselectedMessage(t.services)}/>
                            </div>
                        </div>
                        <div className="row suggestions">
                            <div className="col-md-6">
                                <SuggestionButton icon="&#x1F4F1;" text={t.contact} onClick={() => sendPreselectedMessage(t.contact)}/>
                            </div>
                            <div className="col-md-6">
                                <SuggestionButton icon="&#x1F680;" text={t.partners} onClick={() => sendPreselectedMessage(t.partners)}/>
                            </div>
                        </div>

                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default NewConversationBilingual;