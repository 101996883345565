// import logo from './logo.svg';
import './App.css';
import Main from './components/MainComponent';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from './components/auth/Auth0ProviderWithNavigateComponent';

function App() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <div className="App">
          <Main />
        </div>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  );
}

export default App;
