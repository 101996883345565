import Header from '../HeaderComponent';
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import Footer from '../FooterComponent';

function DeepTechs() {
  return (
    <>
        <Header />

        <Container className="my-4">
            <Row style={{textAlign: 'left'}}>
                <Col sm={12}>
                    <div className="admin-section-title col-sm-10 offset-sm-1">DeepTechs</div>
                </Col>
            </Row>
            <Row className="my-5 d-flex justify-content-center" style={{textAlign: 'left'}}>
                <Col sm={3}>
                    <img src="DeepTechs.webp" alt="DeepTechs" className="img-fluid" />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                    <div>
                        DeepTechs, c’est le Podcast de la rédaction de <a href="https://www.challenges.fr/" target="_blank" className="deeptechs-guest">Challenges</a>.<br /><br />
                        Le principe de notre rendez-vous : pendant 30 minutes, nous parlons d’innovations de rupture, de technologies profondes et de tout ce qu’elles impliquent sur nos modes de vie. On décrypte les tendances, on va au fond des choses avec les meilleurs experts.<br /><br />
                        J'ai le plaisir de coanimer ce podcast avec mon camarade <a href="https://www.linkedin.com/in/gilles-fontaine-89321829/"className="deeptechs-guest" target="_blank">Gilles Fontaine</a>, rédacteur en chef à Challenges.<br /><br />
                        Vous pouvez retrouver tous les episodes des DeepTechs <a href="https://shows.acast.com/deeptechs" target="_blank" className="deeptechs-guest">en cliquant ici</a>.  
                    </div>
                </Col>
            </Row>

        </Container>

        <Container style={{textAlign: 'left'}}>

            <Row>

                <Col sm={{size: 10, offset: 1}}>
                    <div className="blogpost-title mt-3">La sélection littéraire des invités de DeepTechs pour votre été</div>
                </Col>

            </Row>
            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <div className="blogpost-date mb-3">juillet 2024</div>
                </Col>
            </Row>

            <Row>

                <Col className="blogpost-content" sm={{size: 10, offset: 1}}>

                    <div>
                        🌞 C'est l'été et la première saison de DeepTechs, le podcast de <a href="https://www.challenges.fr/" target="_blank" className="deeptechs-guest">Challenges</a> dédié aux technologies profondes, vient de s'achever.<br /><br />
                    </div>

                    <div>
                        Et quelle saison !<br /><br />
                    </div>

                    <div>
                        Avec mon camarade <a href="https://www.linkedin.com/in/gilles-fontaine-89321829/"className="deeptechs-guest" target="_blank">Gilles Fontaine</a>, nous avons plongé au cœur de l'innovation à travers des conversations avec des experts de renom sur un large éventail de sujets : l'intelligence artificielle (notamment l'IA générative), l'informatique quantique, l'IA au service de la santé, la cryptographie, l'éducation, le new space, l'art, le futur de la presse, et les tendances d'investissements en France, aux États-Unis et en Israël.<br /><br />
                    </div>

                    <div>
                        L'occasion pour nous de remercier à nouveau nos invités pour leur disponibilité et pour la qualité de leurs commentaires, ainsi que tous nos très chers auditeurs !<br /><br />
                    </div>

                    <div>
                        DeepTechs ne serait pas ce qu'il est sans notre réalisateur <a href="https://www.linkedin.com/in/charles-elie-labyod-37b40417a/" className="deeptechs-guest" target="_blank">Charlie</a> (le magicien qui efface mes toux répétées) et sans les conseils avisés de <a href="https://www.linkedin.com/in/marcgonnet/"className="deeptechs-guest" target="_blank">Marc Gonnet</a>, le cofondateur et CEO de <a href="https://www.delight-data.com/"className="deeptechs-guest" target="_blank">Delight</a> (et expert du marketing et des podcasts).
                    </div>

                </Col>

            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <div className="blogpost-subtitle">Alors, que lire sur la plage entre deux baignades ?</div>
                </Col>
            </Row>
            <Row>
                <Col className="blogpost-content" sm={{size: 10, offset: 1}}>
                    <div>Pas de panique ! DeepTechs a la solution.</div><br />
                    <div>À la fin de chaque épisode, nous avons demandé à nos invités de partager une recommandation de lecture.</div><br />
                    <div>Voici leurs suggestions, regroupées par thèmes :</div>
                </Col>
            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <div className="blogpost-subtitle">Intelligence artificielle</div>
                </Col>
            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <ul>
                        <li>
                            <p><a href="https://www.linkedin.com/in/lucjulia/" className="deeptechs-guest" target="_blank">Luc Julia</a> (Chief Scientist Officer, Renault) : Les livres de <a href="https://www.fnac.com/SearchResult/ResultList.aspx?Search=jean-gabriel+ganascia&sft=1&sa=0" target="_blank" className="book-recommendation">Jean-Gabriel Ganascia</a>, son ancien professeur d'IA. Nous recommandons également le dernier livre de Luc: "<a href="https://www.fnac.com/a14701726/Luc-Julia-L-intelligence-artificielle-n-existe-pas" className="book-recommendation" target="_blank">L'intelligence artificielle n'existe pas</a>".</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/sergepalaric/" className="deeptechs-guest" target="_blank">Serge Palaric</a> (VP Alliances & OEMs EMEA, Nvidia) : Suggère de lire plusieurs ouvrages sur l'IA pour se forger sa propre opinion, et recommande d'interroger ChatGPT.</p>
                        </li>
                        <li>
                           <p><a href="https://www.linkedin.com/in/laurence-devillers-4041899/" className="deeptechs-guest" target="_blank">Laurence Devillers</a> (Professeure en IA, Sorbonne Université) : "<a href="https://www.fnac.com/a12362218/Laurence-Devillers-Les-robots-emotionnels" className="book-recommendation" target="_blank">Les Robots Émotionnels</a>" et "<a href="https://www.fnac.com/a10170917/Laurence-Devillers-Des-robots-et-des-hommes" className="book-recommendation" target="_blank">Des Robots et des Hommes</a>" - deux ouvrages fascinants sur le monde de la robotique.</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/dalikilani/" className="deeptechs-guest" target="_blank">Dali Kilani</a> (Cofondateur & CTO, FlexAI) : Recommande de suivre les blogs d'<a href="https://openai.com/news/" className="book-recommendation" target="_blank">OpenAI</a> et de <a href="https://ai.meta.com/blog/" className="book-recommendation" target="_blank">Meta AI</a>, ainsi que les travaux de <a href="https://www.linkedin.com/in/gillesbabinet/" className="deeptechs-guest" target="_blank">Gilles Babinet</a>.</p>
                        </li>
                    </ul>
                </Col>
            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <div className="blogpost-subtitle">Informatique quantique</div>
                </Col>
            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <ul>
                        <li>
                            <p><a href="https://www.linkedin.com/in/%C3%A9lie-girard-96067b95/" className="deeptechs-guest" target="_blank">Elie Girard</a> (Chairman, Alice & Bob) : "<a href="https://www.fnac.com/a17188578/Julien-Bobroff-Bienvenue-dans-la-nouvelle-revolution-quantique" className="book-recommendation" target="_blank">Bienvenue dans la nouvelle révolution quantique</a>" de Julien Bobroff.</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/georges-olivier-reymond-415b3824/" className="deeptechs-guest" target="_blank">Georges-Olivier Reymond</a> (Cofondateur & CEO, Pasqal) : "<a href="https://www.amazon.fr/Startup-Silicon-Adventure-Jerry-Kaplan/dp/0140257314/" className="book-recommendation" target="_blank">Startup: A Silicon Valley Adventure</a>" de Jerry Kaplan, qui raconte l'histoire d'une startup ayant inventé le premier ordinateur contrôlé par un stylo.</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/vgiesz/" className="deeptechs-guest" target="_blank">Valerian Giesz</a> (Cofondateur & COO, Quandela) : Les livres de <a href="https://www.fnac.com/SearchResult/ResultList.aspx?Search=Jean-Louis+Basdevant&sft=1&sa=0" className="book-recommendation" target="_blank">Jean-Louis Basdevant</a> sur la mécanique quantique et "<a href="https://www.fnac.com/a16466793/Eric-Reinhardt-Comedies-francaises" className="book-recommendation" target="_blank">Comédies Françaises</a>" d'Éric Reinhardt, retraçant l'histoire du plan calcul.</p>
                        </li>
                    </ul>
                </Col>
            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <div className="blogpost-subtitle">Développement produit</div>
                </Col>
            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <ul>
                        <li>
                            <p><a href="https://www.linkedin.com/in/romainhuet/" className="deeptechs-guest" target="_blank">Romain Huet</a> (Responsable des expériences développeurs, OpenAI) : "<a href="https://www.fnac.com/a13901295/Ken-Kocienda-Creative-Selection" className="book-recommendation" target="_blank">Creative Selection</a>" de Ken Kocienda, qui détaille une approche du développement produit favorisant l'expérimentation et les démos.</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/nejma-belkhdim/" className="deeptechs-guest" target="_blank">Nejma Belkhdim</a> (Cofondatrice & Chief Product Officer, NOLEJ) : "<a href="https://www.fnac.com/a12912083/Nir-Eyal-Hooked-comment-creer-un-produit-ou-un-service-qui-ancre-des-habitudes" className="book-recommendation" target="_blank">Hooked</a>" de Nir Eyal, qui explique comment créer un produit qui ancre des habitudes.</p>
                        </li>
                    </ul>
                </Col>
            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <div className="blogpost-subtitle">Business et Entrepreneuriat</div>
                </Col>
            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <ul>
                        <li>
                            <p><a href="https://www.linkedin.com/in/edouard-cukierman-318733b/" className="deeptechs-guest" target="_blank">Edouard Cukierman</a> (Cofondateur & Managing Partner, Catalyst Investment) : La biographie d'<a href="https://www.fnac.com/a18186970/Walter-Isaacson-Elon-Musk" className="book-recommendation" target="_blank">Elon Musk</a> par Walter Isaacson.</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/charles-beigbeder-b99a3445/" className="deeptechs-guest" target="_blank">Charles Beigbeder</a> (Fondateur, Audacia) : "<a href="https://www.amazon.fr/Power-Law-Venture-Capital-Making/dp/052555999X" className="book-recommendation" target="_blank">The Power Law: Venture Capital and the Making of the New Future</a>" par Sebastian Mallaby.</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/edemaistre/" className="deeptechs-guest" target="_blank">Emmanuel de Maistre</a> (Cofondateur & CEO, Scenario) : "<a href="https://www.fnac.com/a6736907/Ben-Horowitz-The-hard-thing-about-hard-things" className="book-recommendation" target="_blank">The Hard Things about Hard Things</a>" de Ben Horowitz.</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/denisbarrier/" className="deeptechs-guest" target="_blank">Denis Barrier</a> (Cofondateur & CEO, Cathay Innovation) : "<a href="https://www.fnac.com/a8115060/Carmine-Gallo-Talk-like-Ted" className="book-recommendation" target="_blank">Talk like TED</a>" de Carmine Gallo, pour aider les entrepreneurs à "pitcher à l'américaine".</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/jean-schmitt-jolt/" className="deeptechs-guest" target="_blank">Jean Schmitt</a> (Fondateur & Managing Partner, Jolt Capital) : "<a href="https://www.fnac.com/a2640571/Jean-Pierre-Babelon-Henri-IV" className="book-recommendation" target="_blank">Henry IV</a>" de Jean-Pierre Babelon - "Henry IV est un entrepreneur extraordinaire". Il déconseille la lecture de "<a href="https://www.fnac.com/a6509340/A-Geoffrey-Moore-Crossing-the-chasm" className="book-recommendation" target="_blank">Crossing the Chasm</a>" de Geoffrey Moore.</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/thomas-clozel-408a9321/" className="deeptechs-guest" target="_blank">Thomas Clozel</a> (Cofondateur & CEO, Owkin) : "<a href="https://www.fnac.com/a6027953/Philip-Houston-Spy-the-lie" className="book-recommendation" target="_blank">Spy the Lie</a>" de Philip Houston, Michael Floyd, et Susan Carnicero.</p>
                        </li>
                    </ul>
                </Col>
            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <div className="blogpost-subtitle">Technologie et Société</div>
                </Col>
            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <ul>
                        <li>
                            <p><a href="https://www.linkedin.com/in/georgesnahon/" className="deeptechs-guest" target="_blank">Georges Nahon</a> (Analyste et expert de l'innovation) : Recommande de suivre les analyses publiées par les acteurs du capital-risque et de regarder les replays des grandes conférences tech.</p>
                        </li>
                        <li>
                            <p><a href="https://fr.wikipedia.org/wiki/Maurice_L%C3%A9vy_(publicitaire)" className="deeptechs-guest" target="_blank">Maurice Lévy</a> (Publicis, Vivatech, YourArt, Solocal) : Recommande l'auteur <a href="https://www.fnac.com/SearchResult/ResultList.aspx?Search=Kai-Fu+Lee&sft=1&sa=0" className="book-recommendation" target="_blank">Kai-Fu Lee</a>, et "<a href="https://www.fnac.com/a17062263/Henry-Kissinger-The-Age-of-AI" className="book-recommendation" target="_blank">The Age of AI</a>" d'Eric Schmidt, Henry Kissinger, et Daniel Huttenlocher.</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/axeldauchez/" className="deeptechs-guest" target="_blank">Axel Dauchez</a> (Fondateur & CEO, Make.org) : "<a href="https://www.fnac.com/a18796214/Asma-Mhalla-Technopolitique-Comment-la-technologie-fait-de-nous-des-soldats-provisoire" className="book-recommendation" target="_blank">Technolopolitique : Comment la technologie fait de nous des soldats</a>" d'Asma Mhalla.</p>
                        </li>
                        <li>
                            <p><a href="https://fr.wikipedia.org/wiki/Marc_Feuill%C3%A9e" className="deeptechs-guest" target="_blank">Marc Feuillée</a> (Directeur Général, groupe Figaro) : Recommande la lecture des rubriques tech des grands journaux comme <a href="https://www.lefigaro.fr/" className="book-recommendation" target="_blank">Le Figaro</a>, <a href="https://www.lemonde.fr/" className="book-recommendation" target="_blank">Le Monde</a>, et bien sûr, <a href="https://www.challenges.fr/" className="book-recommendation" target="_blank">Challenges</a> &#x1F601;.</p>
                        </li>

                    </ul>
                </Col>
            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <div className="blogpost-subtitle">Littérature et Culture</div>
                </Col>
            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <ul>

                        <li>
                            <p><a href="https://www.linkedin.com/in/ekaterina-balnikov-7ab9b0207/" className="deeptechs-guest" target="_blank">Ekaterina Balnikov</a> (Fondatrice & CEO, Odia) : "<a href="https://www.fnac.com/a18286650/Amin-Maalouf-Le-labyrinthe-des-egares" className="book-recommendation" target="_blank">Le Labyrinthe des égarés</a>" par Amin Maalouf.</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/julien/" className="deeptechs-guest" target="_blank">Julien Codorniou</a> (Investisseur, Felix Capital) : "<a href="https://www.fnac.com/a18172364/Gaspard-Koenig-Humus" className="book-recommendation" target="_blank">Humus</a>" de Gaspard Koenig.</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/sandrinemurcia/" className="deeptechs-guest" target="_blank">Sandrine Murcia</a> (Cofondatrice & CEO, Cosmian) : "<a href="https://www.fnac.com/a16298204/Tana-French-L-arbre-du-mal" className="book-recommendation" target="_blank">L'arbre du mal</a>" de l'autrice américano-irlandaise Tana French.</p>
                        </li>
                        <li>
                            <p><a href="https://fr.wikipedia.org/wiki/Louis_Dreyfus_(homme_de_m%C3%A9dias)" className="deeptechs-guest" target="_blank">Louis Dreyfus</a> (Président du directoire, groupe Le Monde) : "<a href="https://www.fnac.com/a19685805/Philippe-Collin-Le-Barman-du-Ritz" className="book-recommendation" target="_blank">Le Barman du Ritz</a>" de Philippe Collin.</p>
                        </li>
                        <li>
                            <p><a href="https://www.linkedin.com/in/alexandrelebrun/" className="deeptechs-guest" target="_blank">Alexandre Lebrun</a> (Cofondateur & CEO, Nabala) : "<a href="https://www.fnac.com/a13625159/Marguerite-Yourcenar-Bac-2021-Memoires-d-Hadrien" className="book-recommendation" target="_blank">Mémoires d'Hadrien</a>" de Marguerite Yourcenar, qu'il qualifie de "meilleur livre business du monde".</p>
                        </li> 
                    </ul>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col sm={{size: 10, offset: 1}}>
                    <p>Pour ma part - et comme si ces recommandations n'étaient pas déjà suffisamment nombreuses - je me permets d'ajouter à cette liste l'excellent "<a href="https://www.fnac.com/livre-numerique/a16681513/Chris-Miller-Chip-War" className="book-recommendation" target="_blank">Chip War</a>" de Chris Miller. Cet ouvrage offre une plongée fascinante dans l'histoire des semi-conducteurs, un sujet crucial pour comprendre les enjeux technologiques et géopolitiques actuels.</p>
                </Col>
            </Row>

            <Row>
                <Col sm={{size: 10, offset: 1}}>
                    <div className="blogpost-subtitle">On se retrouve à la rentrée pour une passionnante saison 2 de DeepTechs !</div>
                </Col>
            </Row>

            <Row className="mb-5">
                <Col sm={{size: 10, offset: 1}}>
                    <p>Cette première saison de DeepTechs a été riche en découvertes et en échanges passionnants. Nous espérons que ces recommandations de lecture vous permettront d'approfondir les sujets qui vous ont le plus intéressés. <br /><br />

                    Nous vous donnons rendez-vous à la rentrée pour une nouvelle saison tout aussi captivante. D'ici là, n'hésitez pas à rattraper les épisodes que vous auriez manqués sur votre plateforme de podcast préférée ou <a href="https://shows.acast.com/deeptechs" target="_blank" className="deeptechs-guest">sur notre page dédiée</a>.<br /><br />
                    Très bel été à tous et bonnes lectures !</p>
                </Col>
            </Row>

        </Container>

        <Footer language='fr'/>
    </>
  );
}

export default DeepTechs;