import  AdminNavMain from './AdminNavMainComponent';
import {
    Container,
    Row,
    Col,
    Alert,
    Card,
    CardBody,
    CardTitle,
    Button
} from 'reactstrap';
import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { formatTimestamp } from './AdminFuncsComponents'; 
import DOMPurify from 'dompurify';
import AdminPagination from './AdminPaginationComponent';

const RenderHTML = ({ htmlString }) => {
    const cleanHtml = DOMPurify.sanitize(htmlString);
    return <div style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: cleanHtml}} />;
};

function AdminEmails() {

    const { getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState('emptyTokenInAdmin');

    useEffect(() => {
        const getAccessToken = async () => {
            try {
                const jwt = await getAccessTokenSilently();
                setAccessToken(jwt);
            } catch (error) {
                console.log(error.message);
            }
        };
        getAccessToken();
    }, [getAccessTokenSilently]);

    // Managing notification system
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('info');
    const toggleAlert = () => setShowAlert(!showAlert);

    const [emailsList, setEmailsList] = useState([]);

    // Pagination
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    
    const getEmails = useCallback(async () => {
        
        console.log('In getEmails');
        try {
            const emailsPromise = await fetch(process.env.REACT_APP_BACKEND_URL + `/emails?page=${page}&limit=10`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'include'
            });

            if (!emailsPromise.ok) {
                console.log('There is an error fetching the emails');
                let errorMessage = "Unknown error";
                let status = emailsPromise.status;

                try {
                    const errorResponse = await emailsPromise.json();
                    errorMessage = errorResponse.error || errorMessage;
                } catch (err) {
                    console.log('Error parsing error response: ', err);
                }

                const error = new Error(errorMessage);
                error.status = status;
                throw error;
            }

            const emailsList = await emailsPromise.json();
            setEmailsList(emailsList.emails);
            setTotalPages(emailsList.total_pages);

        } catch (err) {
            if (err instanceof TypeError) {
                setAlertMessage(`Fetch error: ${err.message}`);
            } else {
                setAlertMessage(`Error ${err.status || 'unknown'}: ${err.message}`);
            }
            setAlertColor('danger');
            setShowAlert(true);
        }
    }, [page, accessToken]);


    const deleteEmail = async (emailId) => {
        try {
            const deleteEmailPromise = await fetch(process.env.REACT_APP_BACKEND_URL + '/emails/' + emailId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'include'
            });

            if (!deleteEmailPromise.ok) {
                console.log('There is an error deleting the email');
                let errorMessage = "Unknown error";
                let status = deleteEmailPromise.status;

                try {
                    const errorResponse = await deleteEmailPromise.json();
                    errorMessage = errorResponse.error || errorMessage;
                } catch (err) {
                    console.log('Error parsing error response: ', err);
                }

                const error = new Error(errorMessage);
                error.status = status;
                throw error;
            }

            const deleteEmailResponse = await deleteEmailPromise.json();
            console.log('deleteEmailResponse is: ', deleteEmailResponse);

            if (deleteEmailResponse.success === true) {
                setEmailsList(preState => preState.filter(email => email.id !== parseInt(emailId, 10))); //email.id is integer, emailId is string
                setAlertMessage('Email deleted successfully');
                setAlertColor('success');
            } else {
                setAlertMessage(`Error deleting email ${emailId}`);
                setAlertColor('danger');
            }
            setShowAlert(true);
        } catch (err) {
            if (err instanceof TypeError) {
                setAlertMessage(`Fetch error: ${err.message}`);
            } else {
                setAlertMessage(`Error ${err.status || 'unknown'}: ${err.message}`);
            }
            setAlertColor('danger');
            setShowAlert(true);
        }
    };


    useEffect(() => {
        if (accessToken !== 'emptyTokenInAdmin') {
            getEmails();
        }
    }, [accessToken, getEmails]);

    const clickDeleteEmail = async (e) => {
        const emailId = e.target.getAttribute('data-email-id');
        console.log('emailId is: ', emailId);
        deleteEmail(emailId);
    };

    const displayEmailCards = emailsList.map((email) => {
        return (
            <Card key={email.id} className="col-12 col-sm-6">
                <CardBody>
                    <CardTitle>
                        <h5>Email ID: {email.id}</h5>
                    </CardTitle>
                    <div className="my-3" style={{textAlign: 'left'}}>
                        <div><b>conversation id:</b> {email.conversation_id}</div>
                        <div><b>timestamp:</b> {formatTimestamp(email.timestamp)}</div>
                        <div><b>includes link:</b> {email.include_link ? <i className="fa-solid fa-thumbs-up"></i> : <i className="fa-solid fa-thumbs-down"></i>}</div>
                    </div>
                    <div className="my-3" style={{textAlign: 'left'}}>
                        <div><b>email address:</b> {email.email_address}</div>
                        <div className="my-3 pt-3" style={{borderTop: '1px solid pink'}}><b>{email.subject}</b></div>
                        <RenderHTML htmlString={email.body}/>
                    </div>
                </CardBody>
                <div className="col-12 d-flex justify-content-end align-items-end mb-3">
                    <Button
                        size="sm"
                        color="danger"
                        data-email-id={email.id}
                        onClick={clickDeleteEmail}>
                            Delete
                    </Button>
                </div>
            </Card>
        )
    });

    return (
        <>
        <AdminNavMain />
        <Container className="my-3">
            <Row>
                <Col xs={12}>
                    <div className="admin-section-title">Emails</div>
                </Col>
            </Row>
            {alertMessage && (
                <Row className="mt-3">
                    <Alert color={alertColor} isOpen={showAlert} toggle={toggleAlert}>
                        {alertMessage}
                    </Alert>
                </Row>
            )}
            <Row>
                {displayEmailCards}
            </Row>
        </Container>

        <Container className="my-3">
            <Row>
                <Col xs={12} className="d-flex justify-content-center">
                    <AdminPagination
                        page={page}
                        setPage={setPage}
                        totalPages={totalPages}
                    />
                </Col>
            </Row>
        </Container>
        </>
    );
}

export default AdminEmails;