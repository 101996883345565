import React, { memo } from 'react';
import { Table, Button, Input } from 'reactstrap';
import { simplifiedFormatTimestamp, formatTimestamp } from './AdminFuncsComponents';

function DisplayConversationsTable({
    selectedConversation,
    conversationsList,
    handleRowClick,
    isTableReduced,
    conversationsToProcess,
    handleCheckboxMouseDown,
    handleCheckboxChange,
    deleteConversation,
    //formatTimestamp
}){

    return (
        <Table {...(!selectedConversation ? {hover:true} : {})}>
        <thead>
            <tr>
                {!isTableReduced && <th>id</th>}
                {!isTableReduced ? <th>timestamp</th> : <th>date</th>}
                {!isTableReduced && <th>&#x1F917;</th>}
                <th>&#128233;</th>
                <th>&#x1F4E4;</th>
                <th>Summary</th>
                <th>&#9989;</th>
                {!isTableReduced && <th>Delete</th>}
            </tr>
        </thead>
        <tbody>
            {conversationsList.map((conversation) => (
                <React.Fragment key={conversation.id}>
                    <tr 
                        onClick={() => handleRowClick(conversation)}
                        className={selectedConversation === conversation.id ? 'table-light' : ''}>
                        {!isTableReduced && <td>{conversation.id}</td>}
                        <td className="datetime-table-column">{isTableReduced? simplifiedFormatTimestamp(conversation.timestamp) : formatTimestamp(conversation.timestamp)}</td>
                        {!isTableReduced && <td className="center-content">{conversation.user_id}</td>}
                        <td className="center-content">{conversation.messages.length}</td>
                        <td className="center-content">{(conversation.emails && conversation.emails.length !== 0) ? <i className="fa-solid fa-check"></i> : ''}</td>
                        <td className="text-summary-conversation">{conversation.takeaways && conversation.takeaways.length >0 ? conversation.takeaways[0].summary : ''}</td>
                        <td className="center-content">
                            <Input
                                type="checkbox"
                                checked={conversationsToProcess.includes(conversation.id)}
                                onMouseDown={handleCheckboxMouseDown}
                                onChange={(event) => handleCheckboxChange(event, conversation)}
                            />
                        </td>
                        {!isTableReduced && <td className="center-content"><Button size="sm" color="danger" onClick={(event) => deleteConversation(event, conversation.id)}>Delete</Button></td>}
                    </tr>
                    {selectedConversation && selectedConversation === conversation.id && (
                        <tr style={{borderBottom: "2px solid pink"}}>
                            <td colSpan="7">
                                {conversation.messages.map((message) => (
                                    <div className="row" key={message.id}>
                                        {message.is_user_message ?
                                            <div className="admin-user-message col-7 col-md-6 offset-md-1">
                                                <div>&#x1F917;</div>
                                                <div>{message.text}</div>
                                            </div>
                                            :
                                            <div className="admin-josette-message col-7 col-md-6 offset-5">
                                                <div style={{textAlign: 'right'}}>&#x1F916;</div>
                                                <div>{message.text}</div>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </td>
                        </tr>
                    )}
                </React.Fragment>
            ))}
        </tbody>                
    </Table>
    )
}

export default memo(DisplayConversationsTable);