import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { useState } from "react";

function LanguageSwitcher({language, setLanguage}) {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Dropdown className="d-flex justify-content-end" isOpen={isOpen} toggle={toggle}>
            <DropdownToggle size="lg" color="link" caret id="language-switcher-dropdown">
                {language === 'fr' ? String.fromCodePoint(0x1f1eb, 0x1f1f7) : String.fromCodePoint(0x1F1FA, 0x1F1F8)}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => setLanguage('en')}>English</DropdownItem>
                <DropdownItem onClick={() => setLanguage('fr')}>Français</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default LanguageSwitcher;