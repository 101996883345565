import { useState } from 'react';
import {
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { LogoutButton } from '../auth/AuthElementsComponent';

function AdminNavMain() {
    
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const { isAuthenticated } = useAuth0();

    return (
        <Container>
            <Navbar color="light" fixed="top" expand="sm">
                <NavbarBrand href="/admin">Admin</NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ms-auto" navbar>
                        <NavItem>
                            <NavLink href={"/admin/users"}>Users</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href={"/admin/conversations"}>Conversations</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href={"/admin/takeaways"}>Next steps</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href={"/admin/emails"}>Emails</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href={"/admin/messages"}>Messages</NavLink>
                        </NavItem>
                        {isAuthenticated && <NavItem className="d-flex align-items-center justify-content-center"><LogoutButton /></NavItem>}
                    </Nav>
                </Collapse>
            </Navbar>
        </Container>
    )

}

export default AdminNavMain;