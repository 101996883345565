import {
    Container,
    Row,
    Col,
    List
} from "reactstrap";
import Header from "./HeaderComponent";
import Footer from "./FooterComponent";

function PrivacyPolicy() {
    return(
        <>
        <Header />
        
        <Container className="my-4">
            <Row>
                <Col className="col-sm-8 offset-sm-2">
                    <div className="admin-section-title d-flex">Politique de confidentialité de JOSETTE</div>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}} className="col-sm-8 offset-sm-2">
                <Col>
                    <p>
                        Dans le cadre de votre utilisation du site « Josette.co » (ci-après le « Site »), JOSETTE est amenée à traiter vos données à caractère personnel.
                    </p>
                    <p>
                        L’objet de la présente politique de confidentialité et de vous fournir de manière claire, compréhensible et transparente toutes les informations relatives aux traitements de vos données à caractère personnel dans le respect des dispositions applicables en la matière (ci-après la « Réglementation I&amp;L ») comprenant notamment, sans s’y limiter :
                    </p>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}}>
                <Col className="col-sm-8 offset-sm-2">
                <List>
                    <li className="my-2">Le Règlement (UE) 2016/679 du Parlement européen et du Conseil relatif à la protection des personnes physiques à l&#39;égard du traitement des données à caractère personnel et à la libre circulation de ces données, du 27 avril 2016 (ci-après le « RGPD »),</li>
                    <li className="my-2">Les dispositions législatives et réglementaires françaises relatives aux traitements des données personnelles notamment celles découlant de la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés,</li>
                    <li className="my-2">La directive 95/46/CE du Parlement européen et du Conseil relative à la protection des personnes physiques à l&#39;égard du traitement des données à caractère personnel et à la libre circulation de ces données du 24 octobre 1995 et ce jusqu’à l’application du RGPD,</li>
                    <li className="my-2">La directive 2002/58/CE du Parlement européen et du Conseil concernant le traitement des données à caractère personnel et la protection de la vie privée dans le secteur des communications électroniques du 12 juillet 2002, et</li>
                    <li className="my-2">L’ensemble des lignes directrices, opinions, certifications, agréments, recommandations ou décisions de justice définitive, notamment provenant de la Commission nationale de l’informatique et des libertés (ci-après la « CNIL »), et de manière générale toute autre disposition législative et réglementaire établie en matière de protection des données à caractère personnel.</li>
                </List>
                </Col>
            </Row>
            <Row>
                <Col className="mt-4 mb-2 col-sm-8 offset-sm-2">
                    <div className="privacy-policy-title">I. Qui traite mes données et quelles sont ses coordonnées ?</div>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}}>
                <Col className="col-sm-8 offset-sm-2">
                    <p>Au sens de la Réglementation I&amp;L, le responsable de traitements de vos données est la personne qui détermine les finalités et les moyens des traitements. Concernant les traitements opérés sur le Site, le responsable de traitement est la société par actions simplifiée unipersonnelle JOSETTE au capital social de 5 000€, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 979 491 677, dont le siège se situe 72 rue d’Assas à Paris 75006 (ci-après JOSETTE).</p>
                    <p>Il vous est possible de joindre JOSETTE, par téléphone, courriel ou courrier aux coordonnées suivantes :</p>
                    <List>
                        <li>Téléphone : +33 6 85 87 17 72</li>
                        <li>Adresse mail : contact@josette.co</li>
                        <li>Adresse postale : 72 rue d'Assas, 75006 Paris, France</li>
                    </List>
                </Col>
            </Row>
            <Row>
                <Col className="mt-4 mb-2">
                    <div className="privacy-policy-title col-sm-8 offset-sm-2">II. Quelles sont mes données traitées par JOSETTE ?</div>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}}>
                <Col className="col-sm-8 offset-sm-2">
                    <p>Les catégories de vos données à caractère personnel traitées sont :</p>
                    <List>
                        <li>Votre adresse mail et le nom de votre société lorsque vous les renseignez,</li>
                        <li>Votre adresse IP,</li>
                        <li>Un numéro de session personnel attribué lors de votre connexion au Site,</li>
                        <li>Un numéro de référencement lorsque vous entamez une conservation avec notre assistant virtuel,</li>
                        <li>L’heure et la date à laquelle vous avez envoyé vos messages,</li>
                        <li>Le nombre de messages et de connexion au Site, et</li>
                        <li>Toutes les données que vous communiquez par l’utilisation de notre assistant virtuel.</li>
                    </List>
                </Col>
            </Row>

            <Row>
                <Col className="mt-4 mb-2">
                    <div className="privacy-policy-title col-sm-8 offset-sm-2">III. Pourquoi mes données sont traitées et sur quels fondements ?</div>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}} className="col-sm-8 offset-sm-2">
                <Col>
                    <p>Vos données à caractère personnel sont traitées pour les finalités et selon les bases légales suivantes :</p>
                    <List>
                        <li>L’exécution des Conditions Générales d’Utilisation du Site et toutes les mesures administratives et judiciaires y afférant sur la base légale de l’exécution de mesures contractuelles et précontractuelles,</li>
                        <li>La prospection commerciale sur la base légale de l’exécution de mesures contractuelles et précontractuelles, et</li>
                        <li>La création de statistiques et l’entrainement de l’intelligence artificielle de notre assistant virtuel pour l’amélioration des services de JOSETTE sur la base légale de notre intérêt légitime.</li>
                    </List>
                </Col>
            </Row>

            <Row>
                <Col className="mt-4 mb-2">
                    <div className="privacy-policy-title col-sm-8 offset-sm-2">IV. Qui utilise mes données ?</div>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}} className="col-sm-8 offset-sm-2">
                <Col>
                    <p>
                        Dans le cadre des traitements opérés sur vos données à caractères personnel, outre les services internes de JOSETTE, ces dernières sont communiquées à nos sous-traitants de services d’intelligence artificielle du Site, à Auth0, à Google Suite, à Calendly, à nos sous-traitants de services d’hébergement des données du Site et aux services de boîtes de courriels électroniques utilisés par JOSETTE.
                    </p>
                </Col>
            </Row>


            <Row>
                <Col className="mt-4 mb-2">
                    <div className="privacy-policy-title col-sm-8 offset-sm-2">V. Combien de temps mes données sont-elles conservées ?</div>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}}>
                <Col className="col-sm-8 offset-sm-2">
                    <p>Concernant vos données traitées aux fins de l’exécution des Conditions Générales d’Utilisation du Site, elles sont conservées jusqu’au terme de cet accord.</p>
                    <p>Concernant vos données traitées à des fins de prospections commerciales, elles sont conservées pendant une durée de 3 ans à compter de votre dernière action positive justifiant de votre intérêt pour l’utilisation des services de JOSETTE.</p>
                    <p>Concernant vos données traitées à des fins de statistiques et d’entrainement de l’intelligence artificielle de notre assistant virtuel, elles sont conservées jusqu’à leur incorporation dans nos éléments statistiques et leur utilisation pour l’entrainement de l’intelligence artificielle.</p>
                </Col>
            </Row>

            <Row>
                <Col className="mt-4 mb-2">
                    <div className="privacy-policy-title col-sm-8 offset-sm-2">VI. Quels sont mes droits sur mes données ?</div>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}}>
                <Col className="col-sm-8 offset-sm-2">
                    <p>En votre qualité d’utilisateur et de personne concernée par les traitements de données du Site, vous disposez sur vos données traitées d’un :</p>
                    <List>
                        <li>Droit d’accès,</li>
                        <li>Droit de rectification,</li>
                        <li>Droit à l’effacement,</li>
                        <li>Droit à la limitation des traitements,</li>
                        <li>Droit d’opposition, et</li>
                        <li>Droit à la portabilité.</li>
                    </List>
                    <p>Vous disposez également du droit d’introduire une réclamation devant la CNIL.</p>
                    <p>Pour exercer vos droits, vous pouvez faire une demande à l’adresse suivante : contact@josette.co</p>
                </Col>
            </Row>

            <Row>
                <Col className="mt-4 mb-2">
                    <div className="privacy-policy-title col-sm-8 offset-sm-2">VII. Que se passe-t-il si je ne communique pas mes données personnelles ?</div>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}}>
                <Col className="col-sm-8 offset-sm-2">
                    <p>
                    La fourniture de vos données à caractère personnel est indispensable à la conclusion et l’exécution des Conditions Générales d’Utilisation. L’absence de fourniture et de traitements de ces données aurait pour vous une conséquence d’’impossibilité d’accès aux services du Site et donc l’inexécution des obligations de JOSETTE au titre des Conditions Générales d’Utilisation.
                    </p>
                </Col>
            </Row>

            <Row>
                <Col className="mt-4 mb-2">
                    <div className="privacy-policy-title col-sm-8 offset-sm-2">VIII. Mes données sont-elles transférées en dehors de l’Union européenne ?</div>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}}>
                <Col className="col-sm-8 offset-sm-2">
                    <p>
                        Aucune de vos données à caractère personnel ne sont transférées en dehors de l’Union européenne, que ce soit pour leurs traitements par les services internes de JOSETTE ou par nos sous-traitants.
                    </p>
                </Col>
            </Row>

            <Row>
                <Col className="mt-4 mb-2">
                    <div className="privacy-policy-title col-sm-8 offset-sm-2">IX. Mes données sont-elles protégées ?</div>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}}>
                <Col className="col-sm-8 offset-sm-2">
                    <p>
                        JOSETTE protège vos données à caractère personnel lorsqu’elles sont traitées, notamment par nos sous-traitants, par des mesures techniques et organisationnelles appropriées et mises à jour de manière régulière. Ces mesures assurent la protection de vos données contre les risques de perte, mauvaise utilisation, altération et suppression.
                    </p>
                    <p>
                        Malgré toutes ces mesures, le risque zéro n’existe pas sur internet. Ainsi, si vous pensez qu’il existe une atteinte à vos données à caractère personnel, envoyez-nous le plus vite possible un courriel à l’adresse suivante : contact@josette.co
                    </p>
                </Col>
            </Row>

            <Row>
                <Col className="mt-4 mb-2">
                    <div className="privacy-policy-title col-sm-8 offset-sm-2">X. Modification de la politique de confidentialité</div>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}}>
                <Col className="col-sm-8 offset-sm-2">
                    <p>
                        À fin de se conformer à l’évolution de la Réglementation I&amp;L ou dans le cas où nos pratiques sur vos données à caractère personnel changeraient, la présente politique de confidentialité est susceptible de modification.
                    </p>
                    <p>
                        Le cas échéant, vous serez informé de ces modifications par tout moyen approprié, notamment par un affichage dédié sur le Site ou par une communication par courrier électronique. Ces modifications pourront nécessiter un accord exprès de votre part. Dans le cas contraire, l’utilisation postérieure du Site vaut pleine et entière information des modifications apportées à la présente politique de confidentialité de JOSETTE.
                    </p>
                </Col>
            </Row>

        </Container>
        
        <Footer language='fr'/>
        </>
    )
}

export default PrivacyPolicy;