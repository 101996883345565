import { Container, Row, Col } from "reactstrap";
import Marquee from "react-fast-marquee";
import { translations } from "./translations";

function JosetteCustomers({language}) {
    
    const logos = [
        {id: 1, src: 'orange.jpg', alt: 'Orange'},
        {id: 2, src: 'canalplus.jpg', alt: 'Canal+'},
        {id: 3, src: 'imm.svg', alt: 'IMM'},
        {id: 4, src: 'viggo_black.png', alt: 'Viggo'},
        {id: 5, src: 'lace.svg', alt: 'Lace'},
        {id: 6, src: 'orange.jpg', alt: 'Orange'},
        {id: 7, src: 'canalplus.jpg', alt: 'Canal+'},
        {id: 8, src: 'imm.svg', alt: 'IMM'},
        {id: 9, src: 'viggo_black.png', alt: 'Viggo'},
        {id: 10, src: 'lace.svg', alt: 'Lace'}
    ]
    
    const t = translations[language];

    /*
    return (
        <Container>
            <Row>
                <Col className="d-flex justify-content-start align-items-center">
                    <div>Our beloved partners:</div>
                </Col>
            </Row>
        <div className="josette-customers-row">
            <div className="logo-track">
            {[...logos, ...logos, ...logos].map((logo) => (
                <img key={logo.id} src={logo.src} alt={logo.alt} className="josette-customers-logo"/>
            ))}
            </div>
        </div>
        </Container>
    )
    */

    return (
        <Container className="my-5">
            <Row>
                <Col className="d-flex text-aligns-center justify-content-start mb-4">
                    <div className="josette-main-tagline">
                        {t.trustedBy}
                    </div>
                </Col>
            </Row>
            <Row>
                <Marquee speed={50}>
                    <div className="josette-customers-row">
                    {logos.map((logo) => (
                        <img key={logo.id} src={logo.src} alt={logo.alt} className="josette-customers-logo"/>
                    ))}
                    </div>
                </Marquee>
            </Row>
        </Container>
    )
}

export default JosetteCustomers;