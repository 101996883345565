export const suggestedQuestions = {
    en: [
        "What is Josette?",
        "What services do you provide?",
        "How can I get in touch with your team?",
        "Who typically partners with Josette?",
        "Why is your name Josette?",
        "I'm bored, what should I do?",
        "What is DeepTechs?",
        "Do you have a book recommendation?",
        "What's your tech focus?",
        "What topics are you exploring now?",
        "How can Gen AI help my business?",
    ],
    fr: [
        "Qu'est-ce que Josette?",
        "Quels services proposez-vous?",
        "Comment puis-je contacter votre équipe?",
        "Qui sont vos partenaires et clients?",
        "Pourquoi vous appelez-vous Josette?",
        "Je m'ennuie, que devrais-je faire?",
        "Qu'est-ce que DeepTechs?",
        "Avez-vous une recommandation de lecture?",
        "Quel est votre focus technologique?",
        "Quels sujets explorez-vous actuellement?",
        "Comment la Gen AI aide mon entreprise?",
    ]
}
