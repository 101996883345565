import React, {useEffect, useRef, useState, useCallback} from 'react';
import AdminNavMain from './AdminNavMainComponent';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Container,
    Row,
    Col,
    Table,
    Button,
    Input,
    Alert
} from 'reactstrap';
import { formatTimestamp } from './AdminFuncsComponents';
import AdminPagination from './AdminPaginationComponent';

function AdminMessages() {

    const [messagesList, setMessagesList] = useState([]); //list of messages received from backend

    const { getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState('emptyTokenInAdmin');

    const [displayOnlyUserMessages, setDisplayOnlyUserMessages] = useState(false); //display only user messages
    const [isTableReduced, setIsTableReduced] = useState(false); //Reduce the number of columns that are displayed in the table for smaller screens
    
    const [messagesToDelete, setMessagesToDelete] = useState([]); //list of messages to delete

    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('info');
    const [showAlert, setShowAlert] = useState(false);
    const toggleAlert = () => setShowAlert(!showAlert);

    // Pagination
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const getMessages = useCallback(async () => {
        console.log('we are in getMessages');
        try {
            const messagesPromise = await fetch(process.env.REACT_APP_BACKEND_URL + `/messages?page=${page}&limit=30`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                mode: 'cors',
                credentials: 'include'
            });

            if (!messagesPromise.ok) {
                console.log('There is an error fetching messages');
                let errorMessage = 'Unknown error';
                let status = messagesPromise.status;

                try {
                    const errorResponse = await messagesPromise.json();
                    errorMessage = errorResponse.error || errorMessage;
                } catch (err) {
                    console.log('There is an error parsing the error message');
                }

                const error = new Error(errorMessage);
                error.status = status;
                throw error;
            }

            const messages = await messagesPromise.json();
            console.log(messages.messages);
            setMessagesList(messages.messages);
            setTotalPages(messages.total_pages);
        } catch (err) {
            if (err instanceof TypeError) {
                setAlertMessage(`Fetch error: ${err.message}`);
            } else {
                setAlertMessage(`Error ${err.status || 'unknown'}: ${err.message}`);
            }
            setAlertColor('danger');
            setShowAlert(true);
        }
    }, [accessToken, page]);


    useEffect(() => {
        const getAccessToken = async() => {
            try {
                const jwt = await getAccessTokenSilently();
                setAccessToken(jwt);
            } catch (error) {
                console.log(error.message);
            }
        }
        getAccessToken();
    }, [getAccessTokenSilently]);


    useEffect( () => {

        if (accessToken !== 'emptyTokenInAdmin') {
            getMessages();
        };

    }, [accessToken, page, getMessages]);


    const handleCheckboxChange = (event, message) => {
        /*
        if (event.target.checked) {
            setMessagesToDelete([...messagesToDelete, message.id]);
        } else {
            setMessagesToDelete(messagesToDelete.filter(id => id !== message.id));
        }
        */
        setMessagesToDelete(prevState =>
            event.target.checked
                ? [...prevState, message.id]
                : prevState.filter(id => id !== message.id)
        )
    };

    const successfullyDeletedMessages  = useRef([]); //In case of multiple deletes - list of messageIds successfully deleted
    const errorDeletingMessages = useRef([]); //In case of multiple deletes - list of messagesIds that could not be deleted

    const deleteMessage = useCallback(async (messageId, multipleMessages=false) => {
        console.log('We are trying to delete a message from the database');
        console.log('Message id: ', messageId);

        if (messagesToDelete.includes(messageId)) {
            console.log('This is great! We can delete the message');

            try {
                const deleteMessagePromise = await fetch(process.env.REACT_APP_BACKEND_URL + '/messages/' + messageId, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                    mode: 'cors',
                    credentials: 'include'
                });

                console.log('deleteMessagePromise is: ', deleteMessagePromise);

                if (!deleteMessagePromise.ok) {
                    console.log('There is an error deleting the message');
                    let errorMessage = 'Unknown error';
                    let status = deleteMessagePromise.status;

                    try {
                        const errorResponse = await deleteMessagePromise.json();
                        errorMessage = errorResponse.error || errorMessage;
                    } catch (err) {
                        console.log('There is an error parsing the error message');
                    }

                    const error = new Error(errorMessage);
                    error.status = status;
                    throw error;
                }

                const deleteMessageResponse = await deleteMessagePromise.json();
                if (deleteMessageResponse.success) {
                    console.log('about to refresh the messages list for message: ', messageId);
                    setMessagesList(messagesList => messagesList.filter(msg => msg.id !== messageId)); //removing the deleted message from the list
                    console.log('we have refreshed the messages list');
                    setMessagesToDelete(messagesToDelete => messagesToDelete.filter(id => id !== messageId)); //removing the deleted message from the list of messages to delete
                    
                    if(!multipleMessages) {
                        setAlertMessage(`Message ${messageId} has been successfully deleted.`);
                        setAlertColor('success');
                        setShowAlert(true);
                    } else {
                        console.log('We have sucessfully deleted the message: ', messageId);
                        console.log('successfullyDeletedMessages before update: ', successfullyDeletedMessages);
                        successfullyDeletedMessages.current.push(messageId);
                        console.log('successfullyDeletedMessages after update: ', successfullyDeletedMessages);
                    }

                } else {
                    //This is to handle cases where success is false - we should still get a message back.
                    if(!multipleMessages) {
                        setAlertMessage(`Error: ${deleteMessageResponse.error}`);
                        setAlertColor('danger');
                        setShowAlert(true);
                    } else {
                        errorDeletingMessages.current.push(messageId);
                    }
                }
            } catch (error) {
                console.log('There is an error deleting the message');
                if (!multipleMessages) {
                    if (error instanceof TypeError) {
                        setAlertMessage(`Fetch error: ${error.message}`);
                    } else {
                        setAlertMessage(`Error ${error.status || 'unknown'}: ${error.message}`);
                    }
                    setAlertColor('danger');
                    setShowAlert(true);
                } else {
                    errorDeletingMessages.current.push(messageId);
                }
            }

        } else {
            setAlertMessage(`Please select the message ${messageId} before attempting to delete it.`);
            setAlertColor('danger');
            setShowAlert(true);
        }
    }, [messagesToDelete, accessToken]);


    const deleteAllSelectedMessages = async () => {

        successfullyDeletedMessages.current = [];
        errorDeletingMessages.current = [];

        if (messagesToDelete.length === 0) {
            setAlertMessage('Please select messages before attempting to delete them.');
            setAlertColor('danger');
            setShowAlert(true);
            return;
        }

        if (messagesToDelete.length === 1) {
            await deleteMessage(messagesToDelete[0]);
            return;
        }

        try {
            await Promise.all(messagesToDelete.map(async (messageId) => {
                console.log('We are trying to delete message: ', messageId);
                await deleteMessage(messageId, true); //true is to indicate that we are deleting multiple messages
                console.log('Message deleted: ', messageId);
            }));

            console.log('Finished processing message deletion');
            
            /*        
            console.log('listMessgesDeleted is: ', listMessagesDeleted);
            console.log('successfullyDeletedMessages is: ', successfullyDeletedMessages.current);
            setAlertMessage(`All selected messages have been successfully deleted.`);
            setAlertColor('success');
            setShowAlert(true);
            */

        } catch (error) {

            console.log('There is an error deleting the message');
            
            /*
            setAlertMessage(`Error ${error.status || 'unknown'}: ${error.message}`);
            setAlertColor('danger');
            setShowAlert(true);
            */
        }

        const listMessagesDeleted = successfullyDeletedMessages.current.join(', ');
        const listMessagesFailedToDelete = errorDeletingMessages.current.join(', ');

        if (errorDeletingMessages.current.length > 0 && successfullyDeletedMessages.current.length === 0){
            setAlertMessage(`No messages were deleted - Error deleting messages ${listMessagesFailedToDelete}`);
            setAlertColor('danger');
        } else if (successfullyDeletedMessages.current.length > 0 && errorDeletingMessages.current.length !== 0){
            setAlertMessage(`Some errors - Messages ${listMessagesDeleted} deleted successfully.Errors occurred with ${listMessagesFailedToDelete}.`);
            setAlertColor('warning');
        } else {
            setAlertMessage(`All messages deleted - Messages ${listMessagesDeleted} have been successfully deleted.`);
            setAlertColor('success');
        }

        setShowAlert(true);
        setMessagesToDelete([]);   
    };


    const displayMessagesTable = () => {
        return (
            <Table className="table-class-experiment">
                <thead>
                    <tr>
                        <td></td>
                        {!isTableReduced && <th>id</th>}
                        {!isTableReduced && <th>&#x1F4AC;</th>}
                        {!isTableReduced && <th>timestamp</th>}
                        <th>Message</th>
                        <th>&#9989;</th>
                        {!isTableReduced && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {messagesList.map((message) => {
                        if (displayOnlyUserMessages && !message.is_user_message) {
                            return null;
                        } else {
                        return (
                            <tr key={message.id}>
                                {message.is_user_message ? <td className="align-top">&#x1F917;</td> : <td className="align-top">&#x1F916;</td>}
                                {!isTableReduced && <td className="align-top">{message.id}</td>}
                                {!isTableReduced && <td className="align-top">{message.conversation_id}</td>}
                                {/*} <td>{message.timestamp}</td> {*/}
                                {!isTableReduced && <td className="align-top">{formatTimestamp(message.timestamp)}</td>}
                                <td style={{textAlign: 'left'}}>{message.text}</td>
                                <td className="align-top">
                                    <Input
                                        type="checkbox"
                                        checked={messagesToDelete.includes(message.id)}
                                        onChange = {(event) => handleCheckboxChange(event, message)}
                                    />
                                </td>
                                {!isTableReduced && <td className="align-top"><Button size="sm" color="danger" onClick={() => deleteMessage(message.id)}>Delete</Button></td>}
                            </tr>    
                        )
                        }
                    })}
                </tbody>
            </Table>
        )
    }

    return (
        <>  
            <AdminNavMain />
            <Container className="my-3">
                
                <Row>
                    <Col xs={12}>
                        <div className="admin-section-title">Messages</div>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col xs={12}>
                        <div className="form-check form-switch d-flex">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                id="switchDisplayOnlyUserMessages"
                                onChange={() => setDisplayOnlyUserMessages(!displayOnlyUserMessages)}
                            />
                            <label className="form-check-label" htmlFor="switchDisplayOnlyUserMessages">Only display user messages</label>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col xs={12} className="d-flex justify-content-between">
                        <div className="form-check form-switch d-flex align-items-center">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                id="switchReduceTable"
                                onChange={() => setIsTableReduced(!isTableReduced)}
                            />
                            <label className="form-check-label" htmlFor="switchReduceTable">Reduce table size</label>
                        </div>
                        {isTableReduced && (
                            <Button size="sm" color="danger" onClick={deleteAllSelectedMessages}>
                                Delete
                            </Button>
                        )}
                    </Col>
                </Row>

                {alertMessage ?
                    <Row className="mt-3">
                        <Alert color={alertColor} isOpen={showAlert} toggle={toggleAlert}>
                            {alertMessage}
                        </Alert>
                    </Row> 
                : null}

            </Container>

            <Container>
                {displayMessagesTable()}
            </Container>

            <Container className="my-3">
                <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                        <AdminPagination
                            page={page}
                            totalPages={totalPages}
                            setPage={setPage}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AdminMessages;