import {
    Container,
    NavLink,
    Navbar,
    NavbarBrand,
    NavItem,
    Nav,
    Collapse,
    NavbarToggler
} from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton, LogoutButton } from './auth/AuthElementsComponent';
import { useState } from 'react';

function Header() {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const { isAuthenticated } = useAuth0();

    return(
        <Container>
            <Navbar className="josette-header" expand="sm" fixed="top">
                <NavbarBrand href="/">Josette</NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ms-auto" navbar>
                        <NavItem>
                            <NavLink href="/deeptechs">DeepTechs</NavLink>
                        </NavItem>
                        {isAuthenticated && <NavLink href="/labs">Josette Labs</NavLink>}
                        <NavItem className="d-flex align-items-center justify-content-center">
                            {isAuthenticated ?
                                <LogoutButton />
                            :
                                <LoginButton />
                            }
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </Container>

    )
}

export default Header;