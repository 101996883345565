import {Container, Row, Col, Spinner} from 'reactstrap';

function PageLoader() {
    return (
        <Container className="pageloader-container">
            <Row>
                <Col sm={12} className="my-3">
                    <span className="pageloader-loading">Loading...</span>
                </Col>
                <Col sm={12} className="d-flex justify-content-center align-items-center">
                    <Spinner color="primary" />
                </Col>
            </Row>
        </Container>
    );
}

export default PageLoader;