import {
    Pagination,
    PaginationItem,
    PaginationLink
} from 'reactstrap';

function AdminPagination({page, setPage, totalPages}) {

    const updatePage = (pageNumber) => {

        //console.log('In updatePage, pageNumber is: ', pageNumber);

        if (pageNumber <= 1 || pageNumber >= totalPages) {
            return;
        }

        if (pageNumber === page) {
            return;
        }

        setPage(pageNumber);
    }

    return (
        <Pagination>
            <PaginationItem>
                <PaginationLink first onClick={() => updatePage(1)} />
            </PaginationItem>
            <PaginationItem>
                <PaginationLink previous onClick={() => updatePage(page-1)} />
            </PaginationItem>
            {(page - 2 > 0) && (
            <PaginationItem>
                <PaginationLink onClick={() => updatePage(page - 2)}>
                    {page - 2}
                </PaginationLink>
            </PaginationItem>
            )}
            {(page -1 > 0) && (
            <PaginationItem>
                <PaginationLink onClick={() => updatePage(page-1)}>
                    {page -1}
                </PaginationLink>
            </PaginationItem>
            )}
            <PaginationItem active>
                <PaginationLink>
                    {page}
                </PaginationLink>
            </PaginationItem>
            {(page + 1 <= totalPages) && (
                <PaginationItem>
                    <PaginationLink onClick={() => updatePage(page+1)}>
                        {page + 1}
                    </PaginationLink>
                </PaginationItem>
            )}
            {(page + 2 <= totalPages) && (
                <PaginationItem>
                    <PaginationLink onClick={() => updatePage(page+2)}>
                        {page+ 2}
                    </PaginationLink>
                </PaginationItem>
            )}
            <PaginationItem>
                <PaginationLink next onClick={() => updatePage(page +1)} />
            </PaginationItem>
            <PaginationItem>
                <PaginationLink last onClick={() => updatePage(totalPages)} />
            </PaginationItem>
        </Pagination>
    )
};

export default AdminPagination;