import AdminNavMain from './AdminNavMainComponent';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Alert
} from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState, useCallback } from 'react';
import AdminPagination from './AdminPaginationComponent';


function AdminTakeaways() {

    // We manage to get our authentication token from the Auth0 hook
    const { getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState('emptyTokenInAdmin');

    // We get the token in a useEffect
    useEffect(() => {
        const getAccessToken = async () => {
            try {
                const jwt = await getAccessTokenSilently();
                setAccessToken(jwt);
            } catch (error) {
                console.log(error.message);
            }
        };
        getAccessToken();
    }, [getAccessTokenSilently])


    // Managing notification system
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('info');
    const toggleAlert = () => setShowAlert(!showAlert);


    // Pagination
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);


    // We get the list of takeaways from the backend
    const [takeawaysList, setTakeawaysList] = useState([]);
    const [checkboxStates, setCheckboxStates] = useState({});

    const getTakeaways = useCallback(async () => {
        try {
            const takeawaysPromise = await fetch(process.env.REACT_APP_BACKEND_URL + `/takeaways?page=${page}&limit=30`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                mode: 'cors',
                credientials: 'include'
            });

            if (!takeawaysPromise.ok) {
                console.log('There is an error fetching takeaways');
                let errorMessage = "Unknown error";
                let status = takeawaysPromise.status;

                try {
                    const errorResponse = await takeawaysPromise.json();
                    errorMessage = errorResponse.error || errorMessage;
                } catch (err) {
                    console.log('Error parsing error response: ', err);
                }

                const error = new Error(errorMessage);
                error.status = status;
                throw error;
            }

            const takeaways = await takeawaysPromise.json();
            setTakeawaysList(takeaways.takeaways);
            setTotalPages(takeaways.total_pages);

            const initialCheckboxStates = {};
            takeaways.takeaways.forEach((takeaway) => {
                initialCheckboxStates[takeaway.id] = takeaway.reviewed;
            });
            setCheckboxStates(initialCheckboxStates);
    
        } catch (err) {
            if (err instanceof TypeError) {
                setAlertMessage(`Fetch error: ${err.message}`);
            } else {
                setAlertMessage(`Error ${err.status || 'unknown'}: ${err.message}`);
            }
            setAlertColor('danger');
            setShowAlert(true);
        }
    },[accessToken, page]);


    useEffect(() => {
        if (accessToken !== 'emptyTokenInAdmin') {
            getTakeaways();
        }
    }, [accessToken, getTakeaways, page]);


    //const deleteTakeaway = useCallback(async (takeawayId) => {
    const deleteTakeaway = async (takeawayId) => {
        try {
            const deleteTakeawayPromise = await fetch(process.env.REACT_APP_BACKEND_URL + '/takeaways/' + takeawayId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                mode: 'cors',
                credentials: 'include'
            });

            if (!deleteTakeawayPromise.ok) {
                console.log('There is an error deleting the takeaway');
                let errorMessage = "Unknown error";
                let status = deleteTakeawayPromise.status;

                try {
                    const errorResponse = await deleteTakeawayPromise.json();
                    errorMessage = errorResponse.error || errorMessage;
                } catch (err) {
                    console.log('Error parsing error response: ', err);
                }

                const error = new Error(errorMessage);
                error.status = status;
                throw error;
            }

            const deleteTakeawayResponse = await deleteTakeawayPromise.json();
            console.log('Takeaway deleted successfully');

            if (deleteTakeawayResponse.success === true) {
                //setTakeawaysList(takeawaysList.filter((takeaway) => takeaway.id !== takeawayId));
                setTakeawaysList(prevTakeawaysList => prevTakeawaysList.filter(takeaway => takeaway.id !== parseInt(takeawayId, 10)));
                console.log('After the update, takeawaysList is: ', takeawaysList);
                setAlertMessage(`Takeaway ${takeawayId} deleted successfully`);
                setAlertColor('success');
            } else {
                setAlertMessage(`Error deleting takeaway ${takeawayId}`);
                setAlertColor('danger');
            }
            setShowAlert(true);
        } catch (error) {
            if (error instanceof TypeError) {
                setAlertMessage(`Fetch error: ${error.message}`);
            } else {
                setAlertMessage(`Error ${error.status || 'unknown'}: ${error.message}`);
            }
            setAlertColor('danger');
            setShowAlert(true);
        }
    };
    //}, [accessToken, takeawaysList]);


    const clickDeleteTakeaway = async (event) => {
        
        const takeawayId = event.target.dataset.takeawayId;
        
        if (checkboxStates[takeawayId] !== true) {
            setAlertMessage(`Takeaway ${takeawayId} has not been reviewed yet`);
            setAlertColor('danger');
            setShowAlert(true);
            return;
        }
        
        deleteTakeaway(takeawayId);
    };


    const updateTakeaway = useCallback(async (takeawayId, reviewedState) => {
        try {
            const updateTakeawayPromise = await fetch(process.env.REACT_APP_BACKEND_URL + '/takeaways/' + takeawayId, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                mode: 'cors',
                credentials: 'include',
                body: JSON.stringify({ reviewed: reviewedState })
            });

            if (!updateTakeawayPromise.ok) {
                console.log('There is an error updating the takeaway');
                let errorMessage = "Unknown error";
                let status = updateTakeawayPromise.status;

                try {
                    const errorResponse = await updateTakeawayPromise.json();
                    errorMessage = errorResponse.error || errorMessage;
                } catch (err) {
                    console.log('Error parsing error response: ', err);
                }

                const error = new Error(errorMessage);
                error.status = status;
                throw error;
            }

            console.log('Takeaway updated successfully');
            setAlertMessage(`Takeaway ${takeawayId} updated successfully`);
            setAlertColor('success');
            setShowAlert(true);

        } catch (err) {
            if (err instanceof TypeError) {
                setAlertMessage(`Fetch error: ${err.message}`);
            } else {
                setAlertMessage(`Error ${err.status || 'unknown'}: ${err.message}`);
            }
            setAlertColor('danger');
            setShowAlert(true);
        }
    }, [accessToken]);


    const toggleReviewed = (event) => {
        const takeawayId = event.target.dataset.takeawayId;
        const newReviewedState = !checkboxStates[takeawayId];

        setCheckboxStates(prevStates => ({
            ...prevStates,
            [takeawayId]: newReviewedState
        }));

        // Need to update the backend
        updateTakeaway(takeawayId, newReviewedState);
    };


    const displayTakeawaysCards = takeawaysList.map((takeaway) => {

        const userInterests = takeaway.user_interests.map((interest) => {
            return (
                <li key={interest}>{interest}</li>
            )
        })

        return (

            <Card key={takeaway.id} className="col-12 col-sm-6 col-lg-4">
                <CardBody>
                    <CardTitle>
                        <div className="d-flex justify-content-start align-items-center mb-3">
                            <span>takeaway id: <b>{takeaway.id}</b></span>
                        </div>
                        <div className="mb-3">
                            <h5>Conversation ID: {takeaway.conversation_id}</h5>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <span className="me-2">Next steps?</span> {takeaway.next_steps ? <i className="fa-solid fa-thumbs-up"></i> : <i className="fa-solid fa-thumbs-down"></i>}
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="form-check form-switch d-flex">
                                <input
                                    className="form-check-input me-2"
                                    type="checkbox"
                                    checked={checkboxStates[takeaway.id]}
                                    id="flexSwitchCheckDefault"
                                    onChange={toggleReviewed}
                                    data-takeaway-id={takeaway.id}
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Reviewed</label>
                            </div>
                        </div>
                    </CardTitle>
                    <div className="my-3" style={{textAlign: 'left'}}>
                        <div><b>user id:</b> {takeaway.user.id}</div>
                        <div><b>name:</b> {takeaway.user.name || 'N/A'}</div>
                        <div><b>job title:</b> {takeaway.user.job_title || 'N/A'}</div>
                        <div><b>company:</b> {takeaway.user.company || 'N/A'}</div>
                    </div>
                    <div className="my-3">
                        <div style={{textAlign: 'left', fontWeight: 'bold'}}>Summary:</div>
                        <div style={{textAlign: 'left'}}>{takeaway.summary}</div>
                    </div>
                    <div className="my-3" style={{textAlign: 'left'}}>
                        <div><b>User interests:</b></div>
                        <ul>
                            {userInterests}
                        </ul>
                    </div>
                    <div className="my-3" style={{textAlign: 'left'}}>
                        <div ><b>Email sent:</b> {takeaway.email ? '\u2705' : '\u274C'}</div>
                    </div>
                </CardBody>
                <div className="col-12 d-flex justify-content-end align-items-end mb-3">
                    <Button
                        size="sm"
                        color="danger"
                        data-takeaway-id={takeaway.id}
                        onClick={clickDeleteTakeaway}>
                            Delete
                    </Button>
                </div>
            </Card>

        )

    })


    return (
        <>
        <AdminNavMain />
        
        <Container className="my-3">

            <Row>
                <Col xs={12}>
                    <div className="admin-section-title">Next steps</div>
                </Col>
            </Row>
            {alertMessage && (
                <Row>
                    <Col>
                        <Alert color={alertColor} isOpen={showAlert} toggle={toggleAlert}>{alertMessage}</Alert>
                    </Col>
                </Row>    
            )}
            <Row>
                {displayTakeawaysCards}
            </Row>

        </Container>

        <Container className="my-3">
            <Row>
                <Col xs={12} className="d-flex justify-content-center">
                    <AdminPagination
                        page={page}
                        setPage={setPage}
                        totalPages={totalPages}
                    />
                </Col>
            </Row>
        </Container>

        </>
    )
}

export default AdminTakeaways;