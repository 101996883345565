import {
    Container,
    Row,
    Col
} from 'reactstrap';
import Header from '../HeaderComponent';

function Unauthorized() {
    return (
        <>
        <Header />
        <Container style={{paddingTop: '150px'}}>
            <Row>
                <Col sm={12}>
                    <div><b>Unauthorized access</b></div>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <p>You are not authorized to view this page.</p>
                </Col>
            </Row>
        </Container>
        </>
    );
}

export default Unauthorized;