export const translations = {
    en: {
        welcome: 'Welcome to Josette',
        tagline: "Josette is a boutique consulting firm, specializing in generative AI technologies.",
        description: "We support the evolving needs of organizational leaders, CIOs, CTOs, and CEOs to refine strategies, drive action, and achieve competitive advantage through the transformative power of generative AI.",
        helpPrompt: 'How can we help you today?',
        askPlaceholder: 'Discover how Josette can transform your business with AI. Ask me anything?',
        whatIsJosette: 'What is Josette?',
        services: 'What services do you provide?',
        contact: "How can I get in touch with your team?",
        partners: "Who typically partners with Josette?",
        errorMessage: "Oops! Looks like we're experiencing a brief hiccup. Josette is taking a short break but will be back to assist you shortly. Please refresh your page in a few seconds.",
        madeWith: 'Made with',
        byJosette: "by Josette in Paris",
        questions: "If you have any questions, or comments",
        contactUs: "Contact us!",
        trustedBy: "Trusted by industry leaders",
        messageJosette: 'Message Josette...'
    },
    fr: {
        welcome: 'Bienvenue chez Josette',
        tagline: "Josette est un cabinet de conseil spécialisé dans les technologies d'IA générative.",
        description: "Nous accompagnons les dirigeants d'entreprises, DSI, CTO, et CEO dans leur démarche d'innovation. Notre mission: affiner vos stratégies, faire évoluer votre organisation et développer un avantage concurrentiel durable grâce au pouvoir transformateur de l'IA générative.",
        description_option_b: "Nous accompagnons les dirigeants d'organisations, DSI, CTO et CEO dans l'évolution de leurs besoins pour affiner leurs stratégies, faire évoluer leurs organisations, et développer un avantage concurrentiel grâce au pouvoir transformateur de l'IA générative.",
        helpPrompt: 'Comment puis-je vous aider?',
        helpPrompt_option_b: 'Comment pouvons-nous vous aider?',
        askPlaceholder: "Découvrez comment Josette peut transformer votre entreprise grâce à l'IA. Posez-moi toutes vos questions!",
        whatIsJosette: "Qu'est-ce que Josette?",
        services: 'Quels services proposez-vous?',
        contact: "Comment puis-je contacter votre équipe?",
        partners: "Qui sont vos partenaires et clients?",
        errorMessage: "Oups ! Il semble que nous rencontrions un petit problème technique. Josette fait une courte pause mais sera de retour pour vous aider très bientôt. Veuillez rafraîchir votre page dans quelques secondes.",
        madeWith: 'Fait avec',
        byJosette: "par Josette à Paris",
        questions: "Si vous avez des questions, ou des commentaires",
        contactUs: "Contactez-nous!",
        trustedBy: "Ils nous font confiance",
        messageJosette: 'Envoyer un message à Josette...'
    }
}