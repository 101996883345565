import { Routes, Route } from 'react-router-dom';
import Admin from './admin/AdminComponent';
import AdminUsers from './admin/AdminUsersComponent';
import AdminMessages from './admin/AdminMessagesComponent';
import AdminConversations from './admin/AdminConversationsComponent';
import Josette from './JosetteComponent';
import { AuthenticationGuard } from './auth/AuthenticationGuardComponent';
import JosetteLabs from './labs/JosetteLabsComponent';
import AdminEmails from './admin/AdminEmailsComponent';
import AdminTakeaways from './admin/AdminTakeawaysComponent';
import DeepTechs from './blog/DeepTechsComponent';
import PrivacyPolicy from './PrivacyPolicyComponent';


function Main() {
    return (
        <Routes>
            <Route path="/" element={<Josette />} />
            <Route path="/admin" element={<AuthenticationGuard component={Admin} roles={['admin']}/>} />
            <Route path="*" element={<Josette />} />
            <Route path="/admin/users" element={<AuthenticationGuard component={AdminUsers} roles={['admin']}/>} />
            <Route path="/admin/messages" element={<AuthenticationGuard component={AdminMessages} roles={['admin']}/>} />
            <Route path="/admin/conversations" element={<AuthenticationGuard component={AdminConversations} roles={['admin']}/>} />
            <Route path="/labs" element={<AuthenticationGuard component={JosetteLabs} roles={['partner', 'admin']} />} />
            <Route path="/admin/emails" element={<AuthenticationGuard component={AdminEmails} roles={['admin']} />} />
            <Route path="/admin/takeaways" element={<AuthenticationGuard component={AdminTakeaways} roles={['admin']} />} />
            <Route path="/deeptechs" element={<DeepTechs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
    )
}

export default Main;