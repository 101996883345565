export default function SuggestionButtonLegacy({icon, text, onClick}) {
    return (
        <button className="suggestion-button-legacy" onClick={onClick}><span className="icon-button-legacy">{icon}</span> {text}</button>
    )
}

export const SuggestionButton = ({icon, text, onClick}) => {
    return (
        <button className="suggestion-button" onClick={onClick}>
            <span className="icon-button">{icon}</span>
            <span className="text-button">{text}</span>
        </button>
    )
}