import { Container } from "reactstrap";
import { translations } from "./uiComponents/translations";

function Footer({language}){

    const t = translations[language];

    return (
        <footer style={{backgroundColor: 'rgba(254, 249, 250)'}}>
        {/*
            <Container>
                    <div>
                        <div>Made with <i className="fa fa-heart"></i> by Josette in Paris</div>
                        <div>If you have any questions, or comments <a href="mailto:contact@josette.co">contact us!</a></div>
                    </div>
            </Container>
        </footer>
        {*/}

        <Container className="my-4">
                <div>
                    <div className="mb-2">{t.madeWith} <i className="fa fa-heart" style={{color: '#f10404'}}></i> {t.byJosette}</div>
                    {/*}<div>{t.questions} <a href="mailto:contact@josette.co">{t.contactUs}</a></div>{*/}
                    <div><b><a className="footer-links" href="mailto:contact@josette.co">{t.contactUs}</a></b> | <a className="footer-links" href="/privacy-policy">Privacy policy</a></div>
                </div>
        </Container>
        </footer>
    )
}

export default Footer;