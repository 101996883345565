import Header from '../HeaderComponent';
// import Footer from '../FooterComponent';
import { Container, Row, Col } from 'reactstrap';

function JosetteLabs() {
    return (
        <>
        <Header />
        <Container style={{paddingTop: '150px'}}>
            <Row>
                <Col sm={12}>
                    <div><b>Josette Labs</b></div>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <p>Coming soon...</p>
                </Col>
            </Row>
        </Container>
    </>
  );
}

export default JosetteLabs;