import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from 'react';
import PageLoader from './PageLoaderComponent';
// import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Unauthorized from "./UnauthorizedComponent";

export const AuthenticationGuard = ({component, roles = []}) => {
    const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [userRoles, setUsersRoles] = useState([]);

    useEffect(() => {
        const fetchRoles = async() => {
            if (isAuthenticated) {
                try {
                    const jwt = await getAccessTokenSilently();
                    const decodedAccessToken = jwtDecode(jwt);
                    const rolesInToken = decodedAccessToken[`${process.env.REACT_APP_AUTH0_DOMAIN}/roles`] || [];
                    setUsersRoles(rolesInToken);
                } catch (error) {
                    console.log(error.message);
                }
            }
        };
        fetchRoles();
    }, [isAuthenticated, getAccessTokenSilently])

    if (isLoading) {
        return <PageLoader />
    }

    if (!isAuthenticated) {
        loginWithRedirect();
        //return <Navigate to="/login" />
    }

    // Check if a user has the required roles
    const hasRequiredRole = roles.length === 0 || roles.some(role => userRoles.includes(role));

    if (!hasRequiredRole) {
        return (
            <Unauthorized />
        )
        /* return <Navigate to="/unauthorized" /> */
    }

    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <PageLoader />
        )
    });

    return <Component />
};