import React, { useEffect, useState, useCallback } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardText,
    Button,
    CardTitle,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    Alert
} from "reactstrap";
import AdminNavMain from "./AdminNavMainComponent";
import { useAuth0 } from "@auth0/auth0-react";
import AdminPagination from "./AdminPaginationComponent";

function AdminUsers() {
    
    const [usersList, setUsersList] = useState([]);

    //For managing the authentication process
    const { getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState('emptyTokenInAdmin');

    //For managing the editing of the user card
    const [editingUserCard, setEditingUserCard] = useState(false); //Whether or not we should display the modal form
    const [selectedUser, setSelectedUser] = useState({id: 0}); //user selected and passed to the modal form
    const toggleEditUserCard = () => setEditingUserCard(!editingUserCard);

    //For managing the alert on top of our list of users
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('info');


    //Pagination
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect (() => {

        const initialize = async () => {
            try {
                const jwt = await getAccessTokenSilently();
                setAccessToken(jwt);
            } catch (error) {
                console.log(error.message);
            }
        }

        initialize();
    
    }, [getAccessTokenSilently]);


    const getUsers = useCallback(async () => {
        console.log('we are in getUsers');
        try {
            const usersPromise = await fetch(process.env.REACT_APP_BACKEND_URL + `/users?page=${page}&limit=30`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                mode: 'cors',
                credentials: 'include'
            });


            if (!usersPromise.ok) {
                console.log('There is an error fetching users');
                let errorMessage = "Unknown error";
                let status = usersPromise.status;

                try {
                    const errorResponse = await usersPromise.json();
                    errorMessage = errorResponse.error || errorMessage;
                } catch (err) {
                    console.log('There is an error parsing the error response');
                }

                const error = new Error(errorMessage);
                error.status = status;
                throw error;
            }

            const usersResponse = await usersPromise.json();

            if (usersResponse.success === true) {
                console.log(usersResponse.users);
                setUsersList(usersResponse.users);
                setTotalPages(usersResponse.total_pages);
            } else {
                console.log('There is an error fetching users. Success is false');
                setAlertMessage(`Error: ${usersResponse.error}`);
                setAlertColor('danger');
                setShowAlert(true);
            }
        } catch (err) {
            if (err instanceof TypeError) {
                setAlertMessage(`Fetch error: ${err.message}`);
            } else {
                setAlertMessage(`Error ${err.status}: ${err.message}`);
            }
            setAlertColor('danger');
            setShowAlert(true);
        }
    }, [page, accessToken]);


    useEffect(() => {

        if (accessToken !== 'emptyTokenInAdmin') {
            getUsers();
        }

    } , [accessToken, page, getUsers])

    const deleteUser = async (event) => {
        console.log('We are trying to delete a user from the database');
        console.log('User id: ', event.target.dataset.userId);

        try {
            const deletePromise = await fetch(process.env.REACT_APP_BACKEND_URL + '/users/' + event.target.dataset.userId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                mode: 'cors',
                credentials: 'include'
            });

            if (!deletePromise.ok) {
                console.log('There is an error deleting the user');
                let errorMessage = "Unknown error";
                let status = deletePromise.status;

                try {
                    const errorResponse = await deletePromise.json();
                    errorMessage = errorResponse.error || errorMessage;
                } catch (error) {
                    console.log('There is an error parsing the error response');
                }

                const error = new Error(errorMessage);
                error.status = status;
                throw error;
            }

            const deleteResponse = await deletePromise.json();
            console.log('deleteResponse is: ', deleteResponse);

            if (deleteResponse.success === true) {
                console.log('User deleted successfully');
                setUsersList(usersList.filter(user => user.id !== parseInt(event.target.dataset.userId)));
                setAlertMessage(`Deleted user ${event.target.dataset.userId} successfully`);
                setAlertColor('success');
            } else {
                console.log('There is an error deleting the user. Success is false');
                setAlertMessage(`Error: ${deleteResponse.error}`);
                setAlertColor('danger');
            }
            setShowAlert(true);
        } catch (error) {
            if (error instanceof TypeError) {
                setAlertMessage(`Fetch error: ${error.message}`);
            } else {
                setAlertMessage(`Error ${error.status}: ${error.message}`);
            }
            setAlertColor('danger');
            setShowAlert(true);
        }

    };

    const editUser = (event, user) => {
        console.log('We are trying to edit a user from the database');
        console.log('User id: ', event.target.dataset.userId);
        setSelectedUser(user);
        setEditingUserCard(true);
    };

    const displayUsersCards = usersList.map((user) => {

        const conversationsList = user.conversations.map((conversation, index) => {
            return (
                <React.Fragment key={conversation.id}>
                    <a href={`/admin/conversations/${user.id}`}>{conversation.id}</a>
                    {index === user.conversations.length - 1 ? '.' : ', '}
                </React.Fragment>
            )
        });

        return (
            <Card key={user.id} className="col-12 col-sm-6 col-lg-4">
                <CardBody>
                    <CardTitle tag="h5" className="mb-3">User ID: {user.id}</CardTitle>
                    <CardText style={{textAlign: 'left'}}>
                        <b>session id:</b> {user.session_id}<br />
                        <b>IP address:</b> {user.ip_address}<br />
                        <b>name:</b> {user.name}<br />
                        <b>email:</b> {user.email}<br />
                        <b>company:</b> {user.company}<br />
                        <b>job title:</b> {user.job_title}<br />
                        <b>number of connections:</b> {user.numb_connections}<br />
                        <b>conversations: </b> {conversationsList}<br />
                    </CardText>
                    <div className="col-12 d-flex justify-content-between">
                        <Button
                            size="sm"
                            color="primary"
                            data-user-id={user.id}
                            onClick={(event) => editUser(event, user)}>
                                Edit profile
                        </Button>
                        <Button
                            size="sm"
                            color="danger"
                            data-user-id={user.id}
                            onClick={deleteUser}>
                                Delete
                        </Button>
                    </div>
                </CardBody>
            </Card>
        )
    });
    
const EditUserForm = ({user, updateUserCard, toggleEditUserCard}) => {
    
    const [updatedUserData, setUpdatedUserData] = useState({
        id: user.id || "",
        name: user.name || "",
        email: user.email || "",
        company: user.company || "",
        job_title: user.job_title || ""
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setUpdatedUserData({
            ...updatedUserData,
            [name]: value
        });
    };

    const handleSubmit = () => {
        updateUserCard(updatedUserData);
    };

    return (
        <Form>
            <FormGroup row>
                <Col sm={3}>
                    <p><b>connections:</b></p>
                </Col><Col sm={9}>
                    <p>{user.numb_connections}</p>
                </Col>
                <Col sm={3}>
                    <p><b>session id:</b></p>
                </Col><Col sm={9}>
                    <p>{user.session_id}</p>
                </Col>
                <Col sm={3}>
                    <p><b>IP address:</b></p>
                </Col>
                <Col sm={9}>
                    <p>{user.ip_address}</p>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label 
                    for="name"
                    sm={3}
                >
                    <b>name:</b>
                </Label>
                <Col sm={9}>
                    <Input 
                        type="text"
                        name="name"
                        id="name"
                        value={updatedUserData.name}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label 
                    for="email"
                    sm={3}
                >
                    <b>email:</b>
                </Label>
                <Col sm={9}>
                    <Input 
                        type="text"
                        name="email"
                        id="email"
                        value={updatedUserData.email}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label 
                    for="company"
                    sm={3}
                >
                    <b>company:</b>
                </Label>
                <Col sm={9}>
                    <Input 
                        type="text"
                        name="company"
                        id="company"
                        value={updatedUserData.company}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label 
                    for="job_title"
                    sm={3}
                >
                    <b>job title:</b>
                </Label>
                <Col sm={9}>
                    <Input 
                        type="text"
                        name="job_title"
                        id="job_title"
                        value={updatedUserData.job_title}
                        onChange={handleChange}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col sm={12} className="d-flex justify-content-end">
                    <Button color="secondary" outline className="mx-2" onClick={toggleEditUserCard}>Cancel</Button>
                    <Button color="primary" outline onClick={handleSubmit}>Update</Button>
                </Col>
            </FormGroup>
        </Form>
    )
}


const updateUserCard = async (updatedUserData) => { 
    console.log('We are trying to update the user card')
    console.log('Updated user data is: ', updatedUserData);

    try {
        const updatedUserPromise = await fetch(process.env.REACT_APP_BACKEND_URL + '/users/' + updatedUserData.id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(updatedUserData)
        });

        if (!updatedUserPromise.ok) {
            console.log('There is an error updating the user card');
            let errorMessage = "Unknown error";
            let status = updatedUserPromise.status;

            try {
                const errorResponse = await updatedUserPromise.json();
                errorMessage = errorResponse.error || errorMessage;
            } catch (error) {
                console.log('There is an error parsing the error response');
            }

            const error = new Error(errorMessage);
            error.status = status;
            throw error;
        }

        const updatedUserResponse = await updatedUserPromise.json();
        console.log('updatedUserResponse is: ', updatedUserResponse);
        if (updatedUserResponse.success === true) {
            console.log('User card updated successfully');

            const updatedUserList = updatedUserResponse.users;
            setUsersList(updatedUserList);
            const updatedUser = updatedUserResponse.updated_user;

            setAlertMessage(`Updated user ${updatedUser.id}'s profile successfully`);
            setAlertColor('success');
            //we need to update the user card
        } else {
            console.log('There is an error updating the user card. Success is false');
            setAlertMessage(`Error: ${updatedUserResponse.error}`);
            setAlertColor('danger');
        }
        setShowAlert(true);
        toggleEditUserCard();

    } catch (error) {
        if (error instanceof TypeError) {
            setAlertMessage(`Fetch error: ${error.message}`);
        } else {
            setAlertMessage(`Error ${error.status}: ${error.message}`);
        }
        setAlertColor('danger');
        setShowAlert(true);
        toggleEditUserCard();
    }
};

    return (
        <>
            <AdminNavMain />

            <Modal isOpen={editingUserCard} toggle={toggleEditUserCard}>
                <ModalHeader toggle={toggleEditUserCard}>Edit user {selectedUser.id} profile</ModalHeader>
                <ModalBody>
                    <EditUserForm user={selectedUser} toggleEditUserCard={toggleEditUserCard} updateUserCard={updateUserCard}/>
                </ModalBody>
            </Modal>

            <Container className="my-3">
                <Row>
                    <Col>
                        <div className="admin-section-title">Users</div>
                    </Col>
                </Row>
                {alertMessage && (
                    <Row>
                        <Col>
                            <Alert color={alertColor} isOpen={showAlert} toggle={() => setShowAlert(false)}>{alertMessage}</Alert>
                        </Col>
                    </Row>    
                )}
                <Row>
                    {displayUsersCards}
                </Row>
            </Container>

            <Container className="my-3">
                <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                        <AdminPagination 
                            page={page}
                            totalPages={totalPages}
                            setPage={setPage}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AdminUsers;