export const formatTimestamp = (timestamp) => {
    
    if (!timestamp) return '../../..';

    const date = new Date(timestamp);

    const addZero = num => num < 10 ? `0${num}` : num;

    //get the day, month, year
    let day = date.getDate();
    day = addZero(day);

    let month = date.getMonth() + 1;
    month = addZero(month);
    const year = date.getFullYear() % 100; //Get the last two digits of the year

    //get the hours, minutes
    let hours = date.getHours();
    hours = addZero(hours);

    let minutes = date.getMinutes();
    minutes = addZero(minutes);

    return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const simplifiedFormatTimestamp = (timestamp) => {
    
    if (!timestamp) return '../../..';

    const date = new Date(timestamp);

    const addZero = num => num < 10 ? `0${num}` : num;

    //get the day, month, year
    let day = date.getDate();
    day = addZero(day);

    let month = date.getMonth() + 1;
    month = addZero(month);
    const year = date.getFullYear() % 100; //Get the last two digits of the year

    return `${day}/${month}/${year}`;
};
